import AboutHero from '../../assets/images/heros/about-us-hero.png';
import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';

import './EnvironmentalPolicy.css';

export default function EnvironmentalPolicy() {

    return (
        <div className="environmental-policy">

            <MetaData
                title="Environmental Policy"
                image={AboutHero}
            />

            <Hero
                image={AboutHero}
                title="ENVIRONMENTAL POLICY"
            />

            <div className="container pt-5">
                <p>
                    As a responsible and ethical organization, we prioritize managing and mitigating our environmental impact by following industry best practices as they relate to sustainability
                </p>

                <p>
                    This document serves as our environmental policy.
                </p>

                <h2 className="h3">
                    Environmental Objective
                </h2>

                <p>
                    Our commitment to the environment and sustainability is thoroughly integrated into our values, operations, and the services we provide to our clients. We are dedicated to reducing our environmental impact and continually improving our environmental performance as an integral part of our business strategy and operating methods.
                </p>

                <h2 className="h3">
                    Priorities:
                </h2>

                <ul>
                    <li className="pb-3">
                        <p>
                            <strong>Energy Efficiency:</strong><br />
                            We endeavor to maintain our current energy-saving practices and continue to implement additional sustainability measures. We accomplish this by utilizing LED light bulbs and other energy-efficient appliances in our office spaces. We also structure our hyper-local offices for remote and hybrid work, tremendously reducing our employees' carbon emissions as they commute. Our primary HUBZone office also exists on a public transit line in a pedestrian-friendly town center for our employees' convenience.
                        </p>
                    </li>

                    <li className="pb-3">
                        <strong>Sustainable Resource Use:</strong><br />
                        Our employees utilize environmentally friendly, reusable, often secondhand office supplies and actively encourage digital documentation to minimize paper use.
                    </li>

                    <li className="pb-3">
                        <strong>Waste Management:</strong><br />
                        We promote recycling, composting, and other responsible waste disposal in all our office locations.
                    </li>

                    <li className="pb-3">
                        <strong>Resource Allocation:</strong><br />
                        BNL Consulting will ensure its offices have the resources they need to effectively carry out company-wide sustainability initiatives
                    </li>

                    <li className="pb-3">
                        <strong>Continuous Improvement:</strong><br />
                        BNL Consulting is committed to frequent reassessment of our environmental impact and subsequent necessary policy changes. For example, BNL Consulting only uses 15,000 sheets of paper per year, which is well below the national average, but we aim to reduce that usage annually.  
                    </li>

                    <li className="pb-3">
                        <strong>Compliance:</strong><br />
                        Our office ensures compliance with all applicable environmental legislation.
                    </li>
                </ul>

                <h2 className="h3">
                    Communication
                </h2>

                <p className="pb-3">
                    This policy is available on request. If you wish to obtain a copy or would like to discuss our objectives, please click 
                    &nbsp;<a href="http://bnlconsulting.com/contact">here</a>.
                </p>

                <h3 className=" ml-5 pb-1 script-font">
                    Mike Arnett, CEO
                </h3>

                <p className="ml-5 pb-5 date">February 2024</p>
            </div>

        </div>
    );
}