import React from 'react';
import {Link} from 'react-router-dom';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons';

import BNLLogo from '../../../assets/images/bnl-logo.svg';
import SocialButton from '../../Common/SocialButton/SocialButton';

import './Footer.css';

export default function Footer() {
  return (
    <div className="Footer">
      <div className="container py-5">
        <div className="row">

          <div className="col-lg-4 col-md-3 col-sm-12 pb-3 pb-md-0">
            <Link to="/">
              <img className="footer-logo" src={BNLLogo} alt="BNL Consulting"/>
            </Link>

            <a href="tel:8662019322" className="d-block">
              <FontAwesomeIcon className="icon-blue" flip="horizontal" icon={faPhone}/>
              <span className="d-md-none d-lg-inline-block pl-2">(866) 201-9322</span>
            </a>

            <a href="mailto:info@bnlconsulting.com" className="d-block">
              <FontAwesomeIcon className="icon-blue" icon={faEnvelope}/>
              <span className="d-md-none d-lg-inline-block pl-2">info@bnlconsulting.com</span>
            </a>
          </div>

          <div className="col-md-3">
            <div className="footer-title d-none d-md-block">Our Company</div>

            <Link to="/about" className="d-md-block">About Us</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/portfolio" className="d-md-block">Portfolio</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/blog" className="d-md-block">Blog</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/government" className="d-md-block">Government</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/contact" className="d-md-block">Contact Us</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <a href="https://www.indeed.com/cmp/Bnl-Consulting/jobs">Careers</a>
          </div>

          <div className="col-md-3">
            <div className="footer-title d-none d-md-block">Our Services</div>

            <Link to="/data-analytics" className="d-md-block">Data Analytics</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/data-visualization" className="d-md-block">Data Visualization</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/enterprise-integration" className="d-md-block">Enterprise Integration</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/system-modernization" className="d-md-block">System Modernization</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/cloud-services" className="d-md-block">Cloud Services</Link>
            <span className="d-inline-block d-md-none px-1">|</span>

            <Link to="/sas-consulting" className="d-md-block">SAS Consulting</Link>
          </div>

          <div className="col-lg-2 col-sm-3 d-none d-md-block">
            <div className="footer-title">Our offices</div>
            <Link to="/contact#offices" className="d-md-block">Washington, D.C.</Link>
            <Link to="/contact#offices" className="d-md-block">Atlanta</Link>
            <Link to="/contact#offices" className="d-md-block">Athens</Link>
          </div>
        </div>
      </div>

      <div className="Copyright">
        <div className="container clearfix my-2">

          <div className="float-lg-right float-md-right float-sm-right">
            <ul className="list-inline mb-0">
              <li className="list-inline-item">
                <SocialButton
                  type="twitter"
                  className="muted"
                  link={'https://twitter.com/bnl_consulting'}
                  ariaLabel="Twitter"
                />
              </li>
              <li className="list-inline-item px-2">
                <SocialButton
                  type="linkedin"
                  className="muted"
                  link={'https://www.linkedin.com/company/bnl-consulting'}
                  ariaLabel="LinkedIn"
                />
              </li>
              <li className="list-inline-item">
                <SocialButton
                  type="youtube"
                  className="muted"
                  link={'https://www.youtube.com/channel/UCKpbG_0JyJPASTId5SYDMdA'}
                  ariaLabel="YouTube"
                />
              </li>
            </ul>
          </div>

          <div className="small pt-2 text-white">
            Copyright © BNL Consulting {new Date().getFullYear()}
            <span className="px-3">•</span>V{process.env.REACT_APP_VERSION}
            <span className="px-3">•</span><Link to="/privacy">Privacy</Link>
          </div>

        </div>

      </div>

    </div>
  );
}
