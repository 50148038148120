import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import AboutHero from '../../assets/images/heros/about-us-hero.png';
import BnlCulture from '../../assets/images/misc/bnl-culture.jpg';
import BnlCultureTwo from '../../assets/images/misc/bnl-culture2.jpg';
import BnlCultureThree from '../../assets/images/misc/bnl-culture3.jpg';

export default function About() {

  return (
    <div className="BnlLiving">

      <MetaData
        title="BNL Living"
        description="Cultivating Community, Enriching Culture, Driving Change"
        image={AboutHero}
      />

      <Hero
        image={AboutHero}
        title="BNL Living"
        detail="Cultivating Community, Enriching Culture, Driving Change"
      />

      <div className="container">
        <p className="lead pt-5 pb-3">

          At BNL Consulting, we’re more than just a business. At our core, we’re a dedicated community member, deeply
          invested in giving back to the cities who made us who we are today. We’ve woven our commitment to community
          development deeply into the fabric of our organization and reflect these values in our every operation.
        </p>

        <div className="row">
          <div className="col-sm-12"><h2 className="h3">Community</h2></div>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <p>
              We’re dedicated to fueling sustainable growth, forging enduring partnerships, and nurturing an environment
              with shared prosperity. Through our relationships with local organizations, schools, and neighbors, we aim
              to
              cultivate communities that not only flourish economically, but feel safe and empowering for every
              individual.
            </p>
            <p>
              We won’t rest until we’ve made a tangible difference, transforming the areas we belong to into better
              places
              for everyone to live, work, and thrive.
            </p>
          </div>
          <div className="col-sm-4">
            <img alt="BNL Team helping community" src={BnlCulture} className="img-fluid rounded mx-auto d-block px-3 pt-2 w-100"/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-sm-12"><h3>Culture</h3></div>
        </div>
        <div className="row">
          <div className="col-sm-4">
            <img src={BnlCultureTwo} alt='BNL Team At Restaurant' className="img-fluid rounded mx-auto d-block px-3 pt-2 w-100"/>
          </div>
          <div className="col-sm-8">
            <p>
              We understand that you can’t develop your business without giving your employees room to grow. That’s why
              we
              ardently believe in fostering an inclusive and uplifting work environment where everyone, regardless of
              their
              background, can truly flourish. We acknowledge and celebrate the unique perspectives and experiences each
              team
              member brings to the table, understanding that our diversity works as a key part of our success.
            </p>
            <p>
              We prioritize creating a space where every employee feels supported, engaged, and motivated to reach their
              full potential. By nurturing this kind of workplace culture, we're confident in our ability to innovate,
              collaborate, and deliver exceptional results, all while making BNL a fulfilling and rewarding place to
              work.
            </p>
            <p>
              Our passion for data visualization and analytics extends beyond the boundaries of technology and inspires
              us
              every day to act as a force for good in the digital world. Our commitment to ethical change and responsible operations forms the backbone of our company ethos. That’s why we design our
              solutions
              with fairness, transparency, and respect for privacy at their core.
            </p>
            <p>
              We're also dedicated to using our skills and resources to drive positive social change - from partnering
              with
              nonprofits to volunteering our services in community projects, we actively seek out opportunities to make
              a
              meaningful impact. At BNL, we're not just creating innovative data solutions; we're shaping a better, more
              inclusive digital future.

            </p>
          </div>

        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-sm-12"><h3>Change</h3></div>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <p>Our passion for data visualization and analytics extends beyond the boundaries of technology and inspires
              us
              every day to act as a force for good in the digital world. Our commitment to Ethical and responsible
              operations forms the backbone of our company ethos. That’s why we design our solutions with fairness,
              transparency, and respect for privacy at their core.
            </p>
            <p>
              We're also dedicated to using our skills and resources to drive positive social change - from partnering
              with
              nonprofits to volunteering our services in community projects, we actively seek out opportunities to make
              a
              meaningful impact. At BNL, we're not just creating innovative data solutions; we're shaping a better, more
              inclusive digital future.
            </p>
          </div>
          <div className="col-sm-4">
            <img src={BnlCultureThree} alt='Mike speaking at event' className="img-fluid rounded mx-auto d-block px-3 pt-3 w-100"/>
          </div>
        </div>
        <hr className="my-5"/>
      </div>

      <ContactUs
        message="Are you interested in joining our team?"
        buttonText="Current Job Postings"
        href="https://www.indeed.com/cmp/Bnl-Consulting"
      />

    </div>
  );
}
