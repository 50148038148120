import React from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';

import './ContactUs.css';

function ContactUs(props) {
  const {children, message, href, buttonText} = props;

  const hrefButton = (
    <a href={href} target="_blank" rel="noreferrer">
      <button className="btn btn-light">{buttonText}</button>
    </a>
  );

  const linkButton = (
    <Link to="/contact">
      <button className="btn btn-light">{buttonText}</button>
    </Link>
  );

  return (
    <div className="ContactUs internal-promo">
      <div className="container">

        <div className="d-inline-block">
          {message || children}
        </div>

        <span className="d-block d-sm-inline-block m-3"/>

        {href ? hrefButton : linkButton}

      </div>
    </div>
  );
}

ContactUs.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string
};

ContactUs.defaultProps = {
  buttonText: 'Contact Us'
};

export default ContactUs;
