import React from 'react';
import PropTypes from 'prop-types';

function PortfolioSection(props) {
  const {className, backgroundColor, borderColor, children} = props;

  const borderTop = `1px solid ${borderColor}`;
  const style = {backgroundColor, borderTop};

  return (
    <div className={className} style={style}>
      <div className="py-5">
        <div className="container pb-md-2">
          {children}
        </div>
      </div>
    </div>
  );
}

PortfolioSection.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string
};

PortfolioSection.defaultProps = {
  backgroundColor: "#ffffff",
  borderColor: "#ffffff"
};

export default PortfolioSection;
