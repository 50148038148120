import React from 'react';
import PropTypes from 'prop-types';

function CaseStudySection(props) {
  const {className, caption, children} = props;

  return (
    <div className={className}>
      <div className="row pb-4 pb-lg-5">
        <div className="col col-lg-8">
          {children}
        </div>
        <div className="col-lg-2 order-lg-first">
          {caption && <>
            <div className="small text-muted d-lg-none border-top py-3 border-top text-center">
              {caption}
            </div>
            <div className="small d-none d-lg-block border-right text-right pr-3 pb-1">
              <small className="font-weight-light">
                {caption}
              </small>
            </div>
          </>}
        </div>
      </div>
    </div>
  );
}

CaseStudySection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  caption: PropTypes.node
};

export default CaseStudySection;
