import React from 'react';

import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons';
import FormattedDate from '../Common/FormattedDate/FormattedDate';

function BlogOverview(props) {
  const {post} = props;

  return (
    <div className="BlogOverview">
      <h2 className="h4 my-1">
        {post.title}
      </h2>

      <p className="small font-weight-light text-muted">
        Posted by {post.primary_author?.name} on <FormattedDate date={post.published_at}/>
      </p>

      <Link to={`/blog/${post.slug}`}>
        <img className="rounded img-fluid" src={post.feature_image} alt={post.title}/>
      </Link>

      <p className="pt-3">{post.excerpt}</p>

      {post.tags?.map(tag => (
        <Link key={tag.name} to={`/blog?category=${tag.name}`}>
          <span className="badge mr-2 background-gray text-capitalize">
            {tag.name}
          </span>
        </Link>
      ))}

      <Link to={`/blog/${post.slug}`} className="float-right">
        Read More <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
      </Link>

      <hr className="my-5"/>

    </div>
  );
}

BlogOverview.propTypes = {
  post: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string,
    primary_author: PropTypes.shape({
      name: PropTypes.string
    }),
    tags: PropTypes.array,
    published_at: PropTypes.string,
    feature_image: PropTypes.string,
    excerpt: PropTypes.string,
    url: PropTypes.string
  }).isRequired
};

export default BlogOverview;
