import React from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';

import {useTracking} from './components/App/Tracking/useTracking';
import {useScrollToTop} from './components/App/ScrollToTop/useScrollToTop';

import NavBar from './components/App/NavBar/NavBar';
import Footer from './components/App/Footer/Footer';
import CookieConsent from './components/Common/CookieConsent/CookieConsent';

import Portfolio from './pages/Portfolio/Portfolio';
import About from './pages/Company/About';
import BnlLiving from './pages/Company/BnlLiving';
import Blog from './pages/Blog/Blog';
import BlogDetail from './pages/BlogDetail/BlogDetail';
import Contact from './pages/Contact/Contact';
import DataAnalytics from './pages/Services/DataAnalytics';
import DataVisualization from './pages/Services/DataVisualization';
import EnterpriseIntegration from './pages/Services/EnterpriseIntegration';
import SasConsulting from './pages/Services/SasConsulting';
import CloudServices from './pages/Services/CloudServices';
import ProjectManagement from './pages/Services/ProjectManagement';
import SystemModernization from './pages/Services/SystemModernization';

import CyclopsScheduler from './pages/Services/CyclopsScheduler';
import GovernmentContracting from './pages/Services/GovernmentContracting';
import EncounterData from './pages/CaseStudies/EncounterData';
import CdcWisqars from './pages/CaseStudies/CdcWisqars';
import CensusSteps from './pages/CaseStudies/CensusSteps';
import NcbpPreparedness from './pages/CaseStudies/NcbpPreparedness';
import WorldAwareCompanionApplication from './pages/CaseStudies/WorldAware';
import EnterpriseDataLake from './pages/CaseStudies/EnterpriseDataLake';

import Privacy from './pages/Privacy/Privacy';
import Home from './pages/Home/Home';

import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';
import CDCOpiod from './pages/CaseStudies/CDCOpiod';
import EnvironmentalPolicy from './pages/Company/EnvironmentalPolicy';

export default function App() {
  useTracking()
  useScrollToTop()

  return (
    <div className="app">

      <NavBar/>

      <div className="main">
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route exact path="/portfolio" component={Portfolio}/>
          <Route exact path="/about" component={About}/>
          <Route exact path="/bnl-living" component={BnlLiving}/>
          <Route exact path="/blog" component={Blog}/>
          <Route exact path="/blog/:slug" component={BlogDetail}/>
          <Route exact path="/contact" component={Contact}/>
          <Route exact path="/data-analytics" component={DataAnalytics}/>
          <Route exact path="/data-visualization" component={DataVisualization}/>
          <Route exact path="/enterprise-integration" component={EnterpriseIntegration}/>
          <Route exact path="/environmental-policy" component={EnvironmentalPolicy}/>
          <Route exact path="/sas-consulting" component={SasConsulting}/>
          <Route exact path="/cloud-services" component={CloudServices}/>
          <Route exact path="/project-management" component={ProjectManagement}/>
          <Route exact path="/system-modernization" component={SystemModernization}/>
          <Route exact path="/cyclops" component={CyclopsScheduler}/>
          <Route exact path="/government" component={GovernmentContracting}/>
          <Route exact path="/case-studies/encounter-data" component={EncounterData}/>
          <Route exact path="/case-studies/cdc-wisqars-mobile" component={CdcWisqars}/>
          <Route exact path="/case-studies/census-steps" component={CensusSteps}/>
          <Route exact path="/case-studies/ncbp-preparedness" component={NcbpPreparedness}/>
          <Route exact path="/case-studies/opioid" component={CDCOpiod}/>
          <Route exact path="/case-studies/world-aware" component={WorldAwareCompanionApplication}/>
          <Route exact path="/case-studies/edl" component={EnterpriseDataLake}/>

          <Route exact path="/privacy" component={Privacy}/>
          <Route path="/gsa"><Redirect to="/government"/></Route>
          <Route path="*"><Redirect to="/"/></Route>
        </Switch>
      </div>

      <Footer/>

      <CookieConsent/>

    </div>
  )
}

