import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import ncbpIcon from '../../assets/images/ncbp/ncbp_logo_2.svg';
import ncbpScreenASm from '../../assets/images/ncbp/ncbp-screen-a-sm.png';
import ncbpScreenAMd from '../../assets/images/ncbp/ncbp-screen-a-md.png';
import ncbpScreenALg from '../../assets/images/ncbp/ncbp-screen-a-lg.png';
import ncbpScreenA from '../../assets/images/ncbp/ncbp-screen-a-xl.png';
import ncbpScreenBSm from '../../assets/images/ncbp/ncbp-screen-b-sm.png';
import ncbpScreenBMd from '../../assets/images/ncbp/ncbp-screen-b-md.png';
import ncbpScreenBLg from '../../assets/images/ncbp/ncbp-screen-b-lg.png';
import ncbpScreenB from '../../assets/images/ncbp/ncbp-screen-b-xl.png';
import ncbpScreenCSm from '../../assets/images/ncbp/ncbp-screen-c-sm.png';
import ncbpScreenCMd from '../../assets/images/ncbp/ncbp-screen-c-md.png';
import ncbpScreenCLg from '../../assets/images/ncbp/ncbp-screen-c-lg.png';
import ncbpScreenC from '../../assets/images/ncbp/ncbp-screen-c-xl.png';

import caseStudyPdf from '../../assets/documents/ncbp/case-study.pdf';

export default function PortfolioNcbp() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#40454b" borderColor="#000000">

      <div className="pb-5 col-sm-6 offset-sm-3 col-8 offset-2">
        <img
          className="m-auto icon-image"
          alt="NCBP Title Logo"
          src={ncbpIcon}
        />
      </div>

      <ResponsiveImage
        alt="Bio-preparedness data visualizations, google flu trend-lines and query count histograms"
        className="pb-5"
        image={ncbpScreenA}
        largeImg={ncbpScreenALg}
        mediumImg={ncbpScreenAMd}
        smallImg={ncbpScreenASm}
      />

      <ResponsiveImage
        alt="Bio-preparedness data visualizations, ESRI map geospatial GIS EMS signals"
        className="pb-5"
        image={ncbpScreenB}
        largeImg={ncbpScreenBLg}
        mediumImg={ncbpScreenBMd}
        smallImg={ncbpScreenBSm}
      />

      <ResponsiveImage
        alt="Bio-preparedness data visualizations, symptom search results, bar chart, pie chart, word cloud"
        className="pb-5"
        image={ncbpScreenC}
        largeImg={ncbpScreenCLg}
        mediumImg={ncbpScreenCMd}
        smallImg={ncbpScreenCSm}
      />

      <PortfolioDescription
        title="National Collaborative for Bio-Preparedness"
        description="The National Collaborative for Bio-Preparedness (NCB-P) system was designed to provide
          a unified, multi-threat, bio-surveillance resource to improve health-related event detection
          and response."
        createdFor="University of North Carolina Chapel Hill"
        createdForLink="https://www.unc.edu/"
        demo="Request Demo"
        demoLink='https://bnlconsulting.com/contact'
        demoLinkText='Contact Us'
        learnMoreLink="/case-studies/ncbp-preparedness"
        learnMoreText="View Case Study"
        learnMoreDownloadLink={caseStudyPdf}
        learnMoreDownloadName="Ncbp-Case-Study"
      />

    </PortfolioSection>
  );
}
