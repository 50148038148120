import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import CloudHeroImage from '../../assets/images/heros/cloud-services-hero.jpg';
import VectorCloud from '../../assets/images/aws-consulting/vector-cloud.jpg';
import PartnerBadge from '../../assets/images/aws-consulting/aws-partner-badge.png';
import AwsBilling from '../../assets/images/aws-consulting/aws-billing.png';
import AwsArchitecture from '../../assets/images/aws-consulting/aws-architecture-diagram.jpg';

export default function CloudServices() {
  return (

    <div className="CloudServices">

      <MetaData
        title="AWS Consulting: Cloud Migrations, Implementations, Optimizations and More"
        description="As an AWS partner of 10 years, we have the knowledge and experience to find the most
        cost-effective cloud solution for your organization’s unique needs"
        image={CloudHeroImage}
      />

      <Hero
        image={CloudHeroImage}
        title="Cloud Services"
        detail="AWS Consulting and Hosting Services"
        reducedFont={true}
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          Our team of highly qualified AWS-certified cloud consultants has more than a decade of experience
          managing cloud technologies as well as demonstrated expertise in migration, implementation,
          modernization, security, and compliance.
        </p>

        <p>
          When should you opt to modernize your systems instead of simply migrating? Our team of highly
          qualified cloud consultants has administered numerous cloud implementations and migrations and
          can help your enterprise choose the right path, all while ensuring your assets are safe and sound.
        </p>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2 className="h3">
              AWS Cloud Technology Services
            </h2>
            <p>
              We’re proud to partner with Amazon Web Services (AWS) to meet our clients’ sophisticated range of
              cloud needs. Our AWS-certified consultants have implemented comprehensive open-source solutions
              since we first joined forces in 2012. We always perform an in-depth assessment of your enterprise’s
              existing architecture to determine your needs and produce a cost-effective AWS cloud strategy
              unique to your enterprise. And as cloud technology and modernizations continue to increase in
              popularity, we’ll be there to meet the growing needs.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL AWS Consulting Services"
                 src={VectorCloud}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h3>
              Strategically Crafted Implementations
            </h3>
            <p>
              Our team will guide you along a roadmap of specific steps needed to reach your goal.
              Throughout this process, we’ll utilize the AWS Cloud Adoption Framework to consider all
              facets of your organization while we help you develop your cloud strategy. Through our consultants’
              combined knowledge and expertise, we know the best way to design and develop secure and scalable
              AWS solutions critical to your organization's success while also fitting into any budgetary
              and time constraints.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="AWS Architecture Diagram"
                 src={AwsArchitecture}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h3>
              Optimized for Cost
            </h3>
            <p>
              We’ll review your current AWS footprint, assess your current and future cloud needs,
              and then develop a cost optimization strategy tailored to your organization. We can
              employ the AWS Cost Explorer to help identify how much you’re spending for different
              AWS Services, and to reduce billing surprises, we’ll even help you set up AWS Cost Anomaly Detection.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="AWS Billing Dashboard"
                 src={AwsBilling}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h4>
              Your Partner for Cloud Migration
            </h4>
            <p>
              Our team of professionals knows the best way to move your organization to AWS.
              Whether you commit to a full migration to AWS or implement a hybrid solution,
              we’ve got experienced engineers to guide you on the cloud journey. Our AWS certified
              consultants can successfully move your operations to the cloud and leverage services
              like AWS Migration Hub to build a plan and track your progress.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="AWS Partner Badge"
                 style={{maxHeight: 200}}
                 src={PartnerBadge}/>
          </div>
        </div>

        <div className="p-5"/>

      </div>

      <ContactUs>
        Looking for help with your cloud migration?
      </ContactUs>

    </div>
  );
}

