import React from 'react';

import PortfolioSection from './PortfolioSection';

import allDevices from '../../assets/images/misc/all-devices.png';

const CORPORATE_PROFILE = process.env.PUBLIC_URL + '/bnl-company-profile-12_23.pdf';

export default function PortfolioOverview() {

  return (
    <PortfolioSection className="folio-text-dark">

      <div className="row">
        <div className="col-sm-10 offset-sm-1">

          <img
            className="responsive-image py-2 py-sm-4"
            alt="SAS, Microstrategy, iOS, Android, and HTML5 Development"
            src={allDevices}
          />

          <h2 className="h3 pt-4">
            Interface with your information
          </h2>

          <p>
            We have a passion for creating simple, flexible, and elegant
            visual tools to access and manage your data. We see the value
            that lies in your data and understand that an interface must allow
            you to recognize and extract that value without slowing down or
            impeding your progress. We leverage industry-leading technologies
            to extract meaning and transform your data while allowing access
            to it across platforms. But don’t take our word for it, check out a few samples of our work below.
          </p>

        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-6 mx-auto">
          <a href={CORPORATE_PROFILE} className="btn btn-primary btn-block mx-auto" target="_blank" rel="noreferrer">
          Discover what sets our solutions apart from the crowd.
          </a>
      </div>
    </div>

    </PortfolioSection>
  );
}
