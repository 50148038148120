import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import GsaHeroImage from '../../assets/images/heros/gsa-hero.jpg';
import SasHeroImage from '../../assets/images/heros/sas-partner-hero.jpg';

import VectorSas from '../../assets/images/sas-consulting/vector-sas.jpg';
import SasBadge from '../../assets/images/sas-consulting/sas-badge-silver.jpg';
import SasLaptop from '../../assets/images/sas-consulting/sas-laptop.png';
import SasAdmin from '../../assets/images/sas-consulting/viya-admin.jpg';

const SAS_PARTNER_URL = 'https://www.sas.com/en_us/partners/find-a-partner/alliance-partners/us-partners/bnl.html';

export default function SasConsulting() {
  return (

    <div className="SasConsulting">

      <MetaData
        title="SAS Consulting: Viya, Administration, Cloud Migrations, and more"
        description="With over a decade of partnership with SAS, our consultants have the knowledge and experience
        to execute the services your organization requires."
        image={GsaHeroImage}
      />

      <Hero
        image={SasHeroImage}
        title="SAS Consulting"
        detail="Certified help for all your SAS technology"
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          As long-time <a href={SAS_PARTNER_URL} target="_blank" rel="noreferrer">SAS Partners</a>,
          we’ve made a name for ourselves performing services like administration
          and architecture. Our SAS-certified consultants put us in a uniquely advantageous position to
          help your business with its installation, architecture, development, and legacy systems modernization.
        </p>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2 className="h3">
              SAS Consulting Services
            </h2>
            <p>
              As a SAS Alliance Partner with over a decade of experience under our belts, we have a
              deep knowledge and understanding of their software solutions. Hundreds of satisfied
              customers have trusted us with their on-premise implementations,
              remote hosting, and everything in between.
            </p>
            <p>
              Our team of SAS-certified professionals has the level of proficiency you’re looking for to
              deploy a new system, update legacy implementations, or manage a migration. If you’re looking
              for something a little smaller-scale, we can also evaluate a system for performance issues
              or simply test for scalability.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL SAS Consulting Services"
                 src={VectorSas}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h3 className="h4">
              SAS Platform Expertise
            </h3>
            <p>
              We utilize SAS’s variety of technologies and applications to find precisely the right
              data that tells you precisely where your enterprise stands and provides you with
              predictive measures to forecast where you want to go next.
            </p>
            <p>
              Our combined knowledge and experience has allowed us to become a leader in developing
              custom user interfaces built on SAS. The highly interactive and intuitive applications
              we build allow users to isolate critical variables and visualize those variables’
              impact on various outcomes.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="BNL SAS Dashboards"
                 src={SasLaptop}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h4>
              SAS Administration
            </h4>
            <p>
              SAS can help all of your organization’s information work seamlessly throughout your internal
              and external divisions. Here at BNL Consulting, we bring together SAS’s cutting-edge
              database systems, reporting tools, and enterprise software systems to produce collaborative
              systems that work smarter, better, and more efficiently while reducing your risk and costs.
            </p>
            <p>
              Since our inception in 2007, we’ve performed hundreds of SAS to cloud migrations.
              We partner with exceptional companies that create tools like Azure, AWS, and Google Cloud
              to improve scalability, manageability, and adaptability.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL SAS Administration Console"
                 src={SasAdmin}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h4>
              SAS Viya
            </h4>
            <p>
              As SAS Viya experts, we can utilize Viya’s full potential to handle your changing and
              growing analytics demands without batting an eye. Regardless of your data size or how
              often you refresh it, you can trust that the SAS Viya platform will see your project through.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="SAS Silver Partner Badge"
                 style={{maxHeight: 200}}
                 src={SasBadge}/>
          </div>
        </div>

        <div className="p-5"/>

      </div>

      <ContactUs>
        Looking for an expert SAS Administrator or Data Scientist?
      </ContactUs>

    </div>
  );
}
