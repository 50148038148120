import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import CaseStudyDivider from '../../components/CaseStudies/CaseStudyDivider';
import CaseStudySection from '../../components/CaseStudies/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudies/CaseStudyImage';

import OpioidBanner from '../../assets/images/opioid-poc/opioid-banner.jpg';
import OpioidScreenUI from '../../assets/images/opioid-poc/opioid-lg.png';

import caseStudyPdf from '../../assets/documents/opioid/case-study.pdf';

import './CaseStudy.css';

export default function CDCOpiod() {
  return (
    <div className="CdcWisqars">

      <MetaData
        title="National Collaborative for Bio-Preparedness Case Study"
        description="Learn more about the custom mobile applications and analytics framework we developed for the
          Centers for Disease Control and Prevention (CDC)."
        image={OpioidBanner}
      />

      <div>
        <img
          className="responsive-image w-100 border-bottom-banner"
          alt="CDC Wisqars iPads"
          src={OpioidBanner}
        />
      </div>

      <div className="container">

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">Centers for Disease Control and Prevention (CDC):</h1>
          <h5 className="text-muted">Web-based Decision Support Dashboard for National, State, and County Economic Burden of Opioid Overdose and Opioid Use Disorder</h5>

          <a 
            className="btn btn-white"
            href={caseStudyPdf}
            download="Opioid_Case_Study"
            target="_blank"
            rel="noreferrer">
              Download Case Study
          </a>
        </div>

        <CaseStudyDivider>Overview</CaseStudyDivider>

        <CaseStudySection>
          <p>
          The Centers for Disease Control and Prevention is a United States federal agency under the Department
          of Health and Human Services. Headquartered in Atlanta, Georgia, the CDC acts as the nation’s health protection institute. They conduct ongoing, critical scientific research that provides our country with vital health
          information to increase overall health security.
          </p>
          <p>
          The Statistics, Programming, and Economics Branch (SPEB), a component of the CDC and part of the Division
          of Analysis, Research, and Practice Integration (DARPI), is located in the National Center for Injury Prevention
          and Control (NCIPC). The NCIPC’s mission is to reduce the incidence, severity, and adverse outcomes of
          injury, which serves as the leading cause of death between ages 1-44.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>Challenge</CaseStudyDivider>

        <CaseStudySection>
          <p>
          Over the past decade, opioid abuse has grown into a national epidemic. In addition to the obvious humanitarian and health burdens of this persisting problem, financial costs associated with the addiction abuse
          and treatment of this issue create significant difficulties. To combat this continuously rising threat, NCIPC
          wished to create a highly visual and interactive web based decision support dashboard for the national, state, and county levels. This dashboard would effectively illustrate the economic burden of opioid
          overdose and opioid use disorder, and be consistent with and complement the
          Web-based Injury Statistics Query and Reporting System (WISQARS) data visualization module. This resource would assist the
          CDC NCIPC team in their efforts to educate American citizens and especially American policy-makers
          about the cost of the ongoing opioid epidemic in terms of economic impact.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Process</CaseStudyDivider>

        <CaseStudySection>
          <p>
          In order to carry out this project, we had to investigate a few issues relating to Internet Explorer 11 support,
          modify some of the image export logic to support legacy browsers, and extensively test for accessibility
          involving screen readers and voice over software.
          </p>
          <p>
          The coronavirus pandemic introduced unprecedented challenges to this project. CDC stakeholders were
          assigned to COVID-19 related field operations during the project, which condensed the discovery and
          planning phases of the effort and delayed the start of development. BNL was able to quickly adapt the
          project plan, adjust key resource utilization, and tighten the development schedule to perform the required services and deliver on time.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>Our Solution</CaseStudyDivider>

        <CaseStudySection>
          <p>
          In the end, our team crafted a functional React web
          application with interactive geospatial data visualizations based on D3. With this product, the NCIPC and
          CDC’s intended audience now has access to more
          information on the opioid epidemic. This will allow
          viewers to make more informed decisions and draw
          useful, substantial conclusions about the opioid epidemic. Some notable uses include:
          </p>

          <ul>
            <li>
              <p>
                Supports exporting data to CSV, SVG, and PNG
              </p>
            </li>
            <li>
              <p>
              Has the ability to select cost and location
              attributes for filtering and alternate chart or
              tabular displays
              </p>
            </li>
            <li>
              <p>
              Enables the selection of states and counties
              to display opioid use disorder costs for comparison from the national to the county level.
              </p>
            </li>
          </ul> 
        </CaseStudySection>

        <CaseStudyDivider>Technology We Used</CaseStudyDivider>

        <CaseStudySection>
          <p>
          The primary technologies we relied on for this project were a react web application, an ETL process, and
          a NodeJS server wrapper.
          </p>
          <p>
          The React web application is the principal focus of the BNL work effort and the primary location of all
          user-facing components. React consists of a combination of HTML pages, JavaScript code, images, and
          other web resources. Data visualization components, such as bar charts and maps, as well as the logical
          management of user experience and user workflow also belong to the React web application.
          </p>
          <p>
          We used the ETL process for transforming data into the static data set created by a different set of SAS
          programs. These programs will run periodically to recreate one or more of the static datasets and then
          migrate to the production web environment. This process transforms source data into web-friendly formats, applies data models that drive the data visualizations in the UI, and confirms that the data is clean
          and valid.
          </p>
          <p>
          The CDC team had already built and deployed NodeJS web applications prior to our involvement. This
          build process packaged the React web application and the static data into the NodeJS web application
          wrapper, along with a NodeJS web server. This made the new aspects more easily deployable and paralleled the other NodeJS deployments already in place. Throughout this process, we used a standard CDC
          template package for styles, integrated with the CDC’s in-house Adobe analytics processes, and used a
          NodeJS express web server script to mimic the organization’s other app deployments.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>Results</CaseStudyDivider>

        <CaseStudyImage src={OpioidScreenUI} size="wide" />

        <CaseStudySection>
        Our team went above and beyond to meet the needs of this project, accommodating last-minute changes
        and working to meet even the most minor of existing requirements. We delivered all elements on schedule
        and in accordance with the provided budget. We’re proud of the great reviews and feedback we received
        from the NCIPC and CDC team.
        </CaseStudySection>
      </div>

      <ContactUs>
        Want to see what we can build for you?
      </ContactUs>

    </div>
  );
}
