import React from 'react';
import {useLocation} from "react-router-dom";
import {NavDropdown} from 'react-bootstrap';
import PropTypes from "prop-types";

function CustomNavDropdownNav({children, display, activePaths, id}) {
  const pathname = useLocation()?.pathname;

  function isActive() {
    return activePaths.includes(pathname)
  }

  return (
    <NavDropdown id={id} className="services-menu" title={display} active={isActive()}>
      {children}
    </NavDropdown>
  )
}

CustomNavDropdownNav.propTypes = {
  children: PropTypes.node,
  display: PropTypes.string,
  activePaths: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
};

export default CustomNavDropdownNav;
