import React from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';

function BlogContactCard(props) {
  const {className} = props;

  return (
    <div className={className}>
      <div className="card background-dark-gray mb-4">
        <div className="card-body">

          <h3 className="h5 card-title">
            Visualize your data
          </h3>

          <p className="card-text">
            Contact us to see how we can deliver world-class solutions for your business or organization today.
          </p>

          <Link to="/contact">
            <button className="btn btn-outline-light w-100">
              Contact Us
            </button>
          </Link>

        </div>
      </div>
    </div>
  );
}

BlogContactCard.propTypes = {
  className: PropTypes.string
};

export default BlogContactCard;
