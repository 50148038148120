import React from 'react';
import {Link} from 'react-router-dom';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import DataVisualizationHeroImage from '../../assets/images/heros/data-viz-hero.jpg';
import USMap from '../../assets/images/data-visualization/US-Map.png';
import StepTimeline from '../../assets/images/data-visualization/Step-Timeline.jpg';
import ShotChart from '../../assets/images/data-visualization/Shot-Chart.jpg';
import VectorVisualization from '../../assets/images/data-visualization/vector-visualization.jpg';

export default function DataVisualization() {
  return (
    <div className="DataVisualization">

      <MetaData
        title="Data Visualization: Custom Dashboards, Mobile Apps, and more"
        description="Need a more competitive edge? Discover valuable, data-driven insights with our clear,
        concise visuals and a custom user experience and interface."
        image={DataVisualizationHeroImage}
      />

      <Hero
        image={DataVisualizationHeroImage}
        title="DATA VISUALIZATION"
        detail="Your most pertinent information at a glance"
        reducedFont={true}
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          Our experts craft user-centric applications, custom Business Intelligence dashboards, and comprehensive
          visualizations, so you can tell your data story, solve your most complex business decisions,
          and make smarter judgments faster.
        </p>

        <p>
          Does your data have a clear narrative? It will when we’re done with it. Check out
          our <Link to="/portfolio">portfolio</Link> to see some examples.
        </p>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2 className="h3">
              Application and Visualization Solutions
            </h2>
            <p>
              You shouldn’t have to dig through endless spreadsheets and reports to understand your data.
              Integrating your analytics into clear, concise visuals and prioritizing user experience and
              interface can give you a more complete picture of your subject matter.
            </p>
            <p>
              Our comprehensive data visualizations and dashboards give policymakers, stakeholders, and
              business owners a competitive edge. We leverage tools
              like <a href="https://reactjs.org/">React</a>
              , <a href="https://angular.io/">Angular</a>
              , <a href="https://d3js.org/">D3.js</a>
              , <a href="https://developer.apple.com/swift/">Swift</a>
              , and <a href="https://developer.android.com/kotlin">Kotlin</a> to create
              impactful, useful, and beautiful applications and visualizations in less time.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL Mobile data visualizations on iphone X"
                 src={VectorVisualization}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h3>
              Empowering Discovery
            </h3>
            <p>
              The cost of frantically searching through data to find the patterns and information that
              provide useful insights adds up quickly. Additionally, most people aren’t equipped to pore
              over tabular data to quickly identify a trend or anomaly. That's why we create custom interfaces
              that play to your strengths. We present data in a visually compelling manner so you can spot
              outliers, averages, and trends at a glance. We make insightful dashboards that effectively answer
              both the questions you're currently asking and the questions you didn't even know to ask.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="BNL US County Choropleth Map Visualization"
                 src={USMap}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h3>
              Built For Intuition
            </h3>
            <p>
              In theory, a visualization takes large volumes of data and makes the information easy to grasp.
              However, not all representations are created equal. Some data lends itself to visualization more
              easily than others, and generic visual tools can lead to confusion and abstraction, often intimidating
              less-technical users. Truly effective visualization, on the other hand, is intuitive, responsive,
              and accessible to the intended audience. With our Human-Centered Design approach, we have a long
              and distinguished track record of successfully developing web and mobile tools that empower all
              users to explore the full potential of their data.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL Job Status Timeline Visualization"
                 src={StepTimeline}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h3>
              Interactive and Actionable
            </h3>
            <p>
              Visuals alone can speak volumes, and robust, interactive, and collaborative visuals can
              drive organizational focus and direction. We build dynamic mobile and web applications
              that can provide multiple data views on a single screen and adapt to the needs of its end
              users. As certified section 508 compliance practitioners, we know our applications allow
              all users to isolate critical variables, see the impact of those variables on outcomes,
              project future results, and ultimately develop a plan based soundly on data, instead of merely a guess.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="BNL Hexagonal Binning NBA Shot Chart Visualization"
                 src={ShotChart}/>
          </div>
        </div>

        <div className="p-5"/>

      </div>

      <ContactUs>
        Want a custom visual interface to help you make smarter, faster decisions?
      </ContactUs>

    </div>
  );
}
