import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import LayerSlider from '../../components/LayerSlider/LayerSlider';
import HomeServices from '../../components/Home/HomeServices';
import HomeFeatures from '../../components/Home/HomeFeatures';
import HomeTestimonials from '../../components/Home/HomeTestimonials';
import HomePartners from '../../components/Home/HomePartners';
import HomeContact from '../../components/Home/HomeContact';

export default function Home() {
  return (
    <div className="Home">

      <MetaData type="website"/>

      <LayerSlider/>
      <HomeServices/>
      <HomeTestimonials/>
      <HomeFeatures/>
      <HomePartners/>
      <HomeContact/>

    </div>
  );
}
