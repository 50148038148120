import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import healthInsightsIcon from '../../assets/images/health-insights/insights-logo.svg';
import insightsImac from '../../assets/images/health-insights/insights-imac-xl.jpg';
import insightsImacSm from '../../assets/images/health-insights/insights-imac-sm.jpg';
import insightsImacMd from '../../assets/images/health-insights/insights-imac-md.jpg';
import insightsImacLg from '../../assets/images/health-insights/insights-imac-lg.jpg';
import insightsIphonesSm from '../../assets/images/health-insights/insights-iphones-sm.jpg';
import insightsIphonesMd from '../../assets/images/health-insights/insights-iphones-md.jpg';
import insightsIphonesLg from '../../assets/images/health-insights/insights-iphones-lg.jpg';
import insightsIphones from '../../assets/images/health-insights/insights-iphones-xl.jpg';

export default function PortfolioHealthInsights() {

  return (
    <PortfolioSection backgroundColor="#f1f1f1" borderColor="#888888">

      <div className="pb-4 col-sm-4 offset-sm-4 col-8 offset-2">
        <img
          className="m-auto icon-image"
          alt="Health insights title"
          src={healthInsightsIcon}
        />
      </div>

      <ResponsiveImage
        alt="Population health data visualization dashboard, per-capita costs vs readmission rates"
        className="pb-5"
        image={insightsImac}
        largeImg={insightsImacLg}
        mediumImg={insightsImacMd}
        smallImg={insightsImacSm}
      />

      <ResponsiveImage
        alt="Population health data visualization mobile, bivariate choropleth, trend line, scatter plot"
        className="pb-5"
        image={insightsIphones}
        largeImg={insightsIphonesLg}
        mediumImg={insightsIphonesMd}
        smallImg={insightsIphonesSm}
      />

      <PortfolioDescription
        title="Population Health Insights"
        description="A React web application designed to provide exposure to publicly available
        population health data across multiple dimensions such that an analyst could use as a
        tool to identify insights in related data."
        learnMoreText="View Interactive Demo"
        learnMoreLink="https://insights.bnlconsulting.com/"
        demo="Hosted Demo"
        demoLink="https://insights.bnlconsulting.com/"
        demoLinkText="Sample Application"
        createdFor="BNL Consulting LLC"
        createdForLink="https://bnlconsulting.com/"
      />

    </PortfolioSection>
  );
}
