import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import GsaHeroImage from '../../assets/images/heros/gsa-hero.jpg';
import HubzoneBadge from '../../assets/images/government-contracting/hubzone-certified.png';
import IaapBadge from '../../assets/images/government-contracting/iaap-certified.png';
import GsaBadge from '../../assets/images/government-contracting/gsa-contract-gray.png';
import SASM from '../../assets/images/government-contracting/SAFe5-SASM.png';
import SSM from '../../assets/images/government-contracting/SAFe5-SSM.png';
import BNLCapabilitiesStatement from '../../assets/documents/goverment-contracting/BNL_Capabilities_Statement_3_24.pdf';

import DataGraphIcon from '../../assets/images/misc/data-graph-icon.png';
import DataMigrationIcon from '../../assets/images/misc/data-migration-icon.png';
import DataTransferIcon from '../../assets/images/misc/data-transfer-icon.png';
import FederalContractingIcon from '../../assets/images/misc/federal-contracting-icon.png';

const SCHEDULE_URL = 'https://www.gsaelibrary.gsa.gov/ElibMain/contractorInfo.do?contractNumber=47QTCA18D00BT&contractorName=BNL+CONSULTING%2C+LLC&executeQuery=YES';
const HUBZONE_HANDOUT = process.env.PUBLIC_URL + '/hubzone-handout-1-23-v2.pdf';

/*
Sources for federal badges

https://www.sba.gov/brand/external-partners/certified-contractors/
https://www.gsa.gov/reference/gsa-logo-policy/download-gsa-logo
 */

export default function GovernmentContracting() {
  return (

    <div className="GovernmentContracting">

      <MetaData
        title="Federal Professional IT Services"
        description="We offer the most widely used acquisition vehicle in the Federal Government to fulfill the
        complex and ongoing needs of our public sector partners."
        image={GsaHeroImage}
      />

      <Hero
        image={GsaHeroImage}
        title="Government Contracting"
        detail="Federal Procurement Information"
        reducedFont={true}
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          We’re proud to have spent over a decade partnering with organizations like The Centers for Disease Control and
          Prevention, The United States Census Bureau, and The Centers for Medicare and Medicaid Services, and many
          more. Learn about our work together:

        </p>

        <hr className="my-5"/>

        <div className="row">

          <div className="col-md-8 col-sm-12">
            <h2>Contract Vehicles</h2>
            <h3 className="h5 py-2">
              General Services Administration Multiple Award Schedule
            </h3>

            <p>
              We offer the most widely used acquisition vehicle in the Federal Government to fulfill our public sector
              partners' complex and ongoing needs.
            </p>
            <p>
              Our Multiple Award IT Schedule contract signifies that the General Services Administration has determined
              that our pricing is fair and reasonable and that we comply with all applicable laws and regulations.
            </p>
            <p>
              GSA MAS IT Schedules grant government agencies direct access to commercial experts like us so that we can
              thoroughly address the needs of the federal Information Technology marketplace through Special Item
              Numbers (SINS). It also allows for choice, flexibility, ease of use, and access to the highest quality IT
              products, services, and solutions from more than 5,000 certified industry partners.
            </p>
            <p>
              For specific contract information, please visit
              our <a href={SCHEDULE_URL} target="_blank" rel="noreferrer">GSA Schedule Detail Page.</a>
            </p>
            <p>
              View our <a href={BNLCapabilitiesStatement} target="_blank" rel="noreferrer">Federal Capabilities Statement</a>.
            </p>
          </div>
          <div className="col-md-4 col-sm-12">
            <img
              src={GsaBadge}
              className="mb-4 responsive-image w-75"
              alt="BNL GSA Contract Logo"
            />

            <div/>
          </div>
        </div>
        <hr className="my-5"/>

        <div className="row">
          <div className="col-sm-12">
            <h2>Certifications</h2>

            <div className="row">
              <div className="col-sm-9">
                <h3 className="h5 py-2">
                  Small Business Administration Historically Underutilized Business Zones (HUBZone) Certification
                </h3>
                <p>
                  As an SBA-certified HUBZone small business, we've helped fuel the growth of underdeveloped communities
                  by creating new jobs and economic development. This certification also enables us to assist our
                  partners
                  in reaching their small business goals.
                </p>
                <p>
                  <a href={HUBZONE_HANDOUT} className="btn btn-primary" target="_blank" rel="noreferrer">
                    Why do business with a HUBZone company?
                  </a>
                </p>
              </div>
              <div className="col-sm-3 text-center">
                <img
                  src={HubzoneBadge}
                  className="mb-4  ml-5 responsive-image w-50"
                  alt="BNL is Hubzone Certified"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-9">
                <h3 className="h5 py-2">SAFe® Scrum Master</h3>
                <p>Our Project Managers demonstrate Agile and Scrum mastery, scalability and collaboration skills,
                  emphasis on value delivery and productivity, empowerment and servant leadership capabilities, and a
                  deep
                  understanding of the SAFe framework.</p>
              </div>
              <div className="col-sm-3 text-center">

                <img
                  src={SSM}
                  className="mb-4  ml-5 responsive-image w-75"
                  alt="SAFe 5 SSM Certified"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-9">
                <h3 className="h5 py-2">SAFe® Advanced Scrum Master</h3>
                <p>
                  This certification signifies advanced knowledge and expertise in scaling Agile practices, facilitating
                  Agile transformations, promoting collaboration and leadership, and embracing continuous improvement
                  and
                  Lean thinking.
                </p>
              </div>
              <div className="col-sm-3 text-center">

                <img
                  src={SASM}
                  className="mb-4 ml-5 responsive-image w-75"
                  alt="SAFe 5 SASM Certified"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-sm-9">
                <h3 className="h5 py-2">International Association of Accessibility Professionals Web Accessibility
                  Specialist</h3>
                <p>With our IAAP WAS certification, we can ensure that our projects are inclusive and accessible to a
                  wider audience, including people with visual impairments, hearing impairments, mobility limitations,
                  and
                  other disabilities. This certification demonstrates our strong commitment to inclusivity, compliance
                  with accessibility regulations, and the ability to provide an optimal user experience for all
                  individuals. [508 verbiage]</p>
              </div>
              <div className="col-sm-3 text-center">
                <img
                  src={IaapBadge}
                  className="mb-4 ml-5 responsive-image w-75"
                  alt="BNL is IAAP Web Accessibility Certified"
                />
              </div>
            </div>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">

          <div className="col-sm-12">
            <h2>Contracts Held</h2>

            <div className="row">
              <div className="col-sm-3 text-center">
                <img src={DataGraphIcon} alt='data graphic' className="py-3 mr-5 responsive-image w-75 rounded"/>
              </div>
              <div className="col-sm-9">
                <h3 className="h5 py-2">
                  CDC WISQARS
                </h3>
                <hr />
                <h6 className="font-italic">
                  Prime Contractor
                </h6>
                <p>In 2012, we assisted the CDC with creating the Web-based Injury Statistics Query and Reporting System
                  (WISQARS) application, a custom data visualization application with the ability to provide injury data
                  to
                  researchers and other data consumers. The application suited the CDC well for several years, but with
                  the
                  onset of new technological advancements, it grew apparent that to keep serving its purpose, WISQARS
                  would
                  need to modernize.
                </p>
                <p>
                  To learn more about our solution, <a href="/portfolio">view our portfolio</a>
                </p>
              </div>
            </div>


            <div className="row pt-5">
              <div className="col-sm-3 text-center">
                <img src={DataGraphIcon} alt='data graphic' className="py-3 mr-5 responsive-image w-75 rounded"/>
              </div>
              <div className="col-sm-9">
                <h3 className="h5 py-2">
                  CDC: Web-based Decision Support Dashboard for National, State, and County Economic Burden of Opioid
                  Overdose and Opioid Use Disorder
                </h3>
                <hr />
                <h6 className="font-italic">
                  Prime Contractor
                </h6>
                <p>To combat the continuously rising threat of opioid abuse, the National Center for Injury Prevention
                  and
                  Control (NCIPC) within the CDC wished to create a highly visual and interactive web-based decision
                  support
                  dashboard for the national, state, and county levels. This dashboard would effectively illustrate the
                  economic burden of opioid overdose and opioid use disorder and be consistent with and complement the
                  Web-based Injury Statistics Query and Reporting System (WISQARS) data visualization module.
                </p>
              </div>
            </div>

            <div className="row pt-5">
              <div className="col-sm-3 text-center">
                <img src={DataTransferIcon} alt='data transfer graphic' className="py-3 mr-5 responsive-image w-75 rounded"/>
              </div>
              <div className="col-sm-9">
                <h3 className="h5 py-2">
                  CDC: HIV Surveillance Branch’s Data Visualization Tool for Technical Assistance
                </h3>
                <hr />
                <h6 className="font-italic">
                  Prime Contractor
                </h6>
                <p>The HIV Surveillance Branch (HSB) in the Division of HIV Prevention (DHP) within the National Center
                  for
                  HIV/AIDS, Viral Hepatitis, STD, and TB Prevention (NCHHSTP) at the Centers for Disease Control and
                  Prevention (CDC) conducts a National HIV surveillance program to characterize and monitor the HIV
                  burden,
                  determinants, and impact to guide public health action, prevent new HIV infections, improve health
                  outcomes and promote health equity.
                </p>
                <p>
                  With this contract, we’ll consolidate data sources used to monitor performance and other metrics
                  historically stored in disparate systems, different formats, and complex file folder structures. This
                  effort will help HSB understand complex data and communicate the information effectively to a variety
                  of
                  stakeholders.
                </p>
              </div>
            </div>

            <div className="row pt-5">
              <div className="col-sm-3 text-center">
                <img src={DataMigrationIcon} alt='data migration icon' className="py-3 mr-5 responsive-image w-75 rounded"/>
              </div>
              <div className="col-sm-9">
                <h3 className="h5 py-2">Department of Health and Human Services: Data Analytics Supporting Healthcare
                  (DASH)</h3>
                <hr />
                <h6 className="font-italic">
                  Subcontractor
                </h6>
                <p>We were honored to be selected by the Centers for Medicare and Medicaid Services for their DASH BPA
                  in
                  February of 2022. We look forward to helping CMS advance their quality analytics initiatives and
                  modernizing their analytics platforms.</p>
              </div>
            </div>

            <div className="row pt-5">
              <div className="col-sm-3 text-center">
                <img src={DataMigrationIcon} alt='data migration icon' className="py-3 mr-5 responsive-image w-75 rounded"/>
              </div>
              <div className="col-sm-9">
                <h3 className="h5 py-2">Department of Justice: Office of Justice Programs</h3>
                <hr />
                <h6 className="font-italic">
                  Prime Contractor
                </h6>
                <p>The DOJ Office of Justice Programs (OJP) needed more processing power, better speed, and a web-based
                  experience that used in-memory processing to support their constantly increasing quantities of data.
                  To
                  this end, OJP tasked BNL with migrating their current SAS configuration to SAS Global Hosting with
                  Viya4
                  on Azure to facilitate desired processing advancements, provide better governance, and position OJP
                  for
                  the future.</p>
              </div>
            </div>
            <div className="row pt-5">
              <div className="col-sm-3 text-center">
                <img src={FederalContractingIcon} alt='data migration icon' className="py-3 mr-5 responsive-image w-75 rounded"/>
              </div>
              <div className="col-sm-9">
                <h3 className="h5 py-2">USDA:  National Finance Center (NFC) Technical Support Surge</h3>
                <hr />
                <h6 className="font-italic">
                  Prime Contractor
                </h6>
                <p>The National Finance Center (NFC) is the USDA’s Shared Service Provider for Financial Management Services and Human Resources Management Services like payroll, budgeting, and financial reporting. They assist agencies in achieving cost-effective, standardized, and interoperable solutions that provide functionality to support strategic financial management and human resource management direction. Due to the complex nature of their work, the NFC’s high-level development needs had exceeded their internal capabilities. In order to enact much needed changes and upgrades, they required external expertise.</p>
              </div>
            </div>
          </div>
        </div>

        <div className="my-5"/>

      </div>

      <ContactUs>
        Looking to partner on an upcoming opportunity?
      </ContactUs>;

    </div>
  );
}
