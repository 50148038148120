import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useTracking = (trackingId = process.env.REACT_APP_GA_ID) => {
  const location = useLocation();

  useEffect(() => {
    if (!window.gtag) {
      return;
    }

    if (!trackingId) {
      console.log('GA Tracking not enabled, missing `trackingId` or `REACT_APP_GA_ID`.');
      return;
    }

    if (process.env.NODE_ENV !== 'production') {
      console.log('GA Tracking is not enabled for development builds');
      return;
    }

    window.gtag('config', trackingId, {page_path: location.pathname});

  }, [trackingId, location])

}
