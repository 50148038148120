import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt, faSquare} from '@fortawesome/free-solid-svg-icons';

export default function OfficeAddress(props) {
  const {mapLink, locationName, addressLine1, addressLine2, phone} = props;

  return (
    <div className="OfficeAddress text-center">
      <div className="container">
        <h3 className="h4">
          <a href={mapLink} target="_blank" rel="noreferrer" aria-label={locationName}>
            <span className="fa-layers fa-1x m-3">
              <FontAwesomeIcon icon={faSquare} transform="grow-18" color="#3371a8"/>
              <FontAwesomeIcon icon={faMapMarkerAlt} inverse/>
            </span>
          </a>
        </h3>
        <h4 className="text-nowrap h5">{locationName}</h4>
        <p className="text-nowrap pb-4 pb-sm-0">
          <a href={mapLink} target="_blank" rel="noreferrer">
            {addressLine1}
            <br/>
            {addressLine2}
          </a>
          {!!phone && (
            <>
              <br/>
              Phone: {phone}
            </>
          )}
        </p>
      </div>
    </div>
  );
}

OfficeAddress.propTypes = {
  mapLink: PropTypes.string,
  locationName: PropTypes.string,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  phone: PropTypes.string
};
