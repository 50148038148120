import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import worldAwareScreen from '../../assets/images/world-aware/world-aware-screen.png';

import caseStudyPdf from '../../assets/documents/wordAware/case-study.pdf';

export default function PortfolioWorldAware() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#40454b" borderColor="#000000">

      <ResponsiveImage
        alt="After our team completed the work described here, WorldAware was acquired by Crisis24, a GardaWorld company."
        className="pb-5"
        image={worldAwareScreen}
      />

      <PortfolioDescription
        title="WorldAware Companion Application"
        description={"After our team completed the work described here, WorldAware was acquired by Crisis24, a GardaWorld company. When WorldAware sought to give their legacy application, Companion, a more modern look and feel, we brought the outdated platform and their additional acquired technologies to the forefront of cutting-edge innovation.  By integrating their disparate stacks into a hybrid web application tailored to their unique needs, we helped WorldAware satisfy existing customers, expand their clientele, and win more business by expanding the flagship application features and offerings."
        }
        createdFor="University of North Carolina Chapel Hill"
        createdForLink="https://www.unc.edu/"
        demo="Request Demo"
        demoLink='https://bnlconsulting.com/contact'
        demoLinkText='Contact Us'
        learnMoreLink="/case-studies/world-aware"
        learnMoreText="View Case Study"
        learnMoreDownloadLink={caseStudyPdf}
        learnMoreDownloadName="World-Aware-Case-Study"
      />

    </PortfolioSection>
  );
}
