import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import CaseStudyDivider from '../../components/CaseStudies/CaseStudyDivider';
import CaseStudySection from '../../components/CaseStudies/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudies/CaseStudyImage';

import CdcBanner from '../../assets/images/cdc/cdc-banner.jpg';
import CdcMockups from '../../assets/images/cdc/Mockups.jpg';
import CdcMobileLandscape from '../../assets/images/cdc/Mobile-Landscape.jpg';
import CdcTabletDetail from '../../assets/images/cdc/Tablet-Detail-Full.png';
import CdcPerspective from '../../assets/images/cdc/cdc-perspective.jpg';

import caseStudyPdf from '../../assets/documents/wisqars/case-study.pdf';

export default function GsaSchedule() {
  return (
    <div className="CdcWisqars">

      <MetaData
        title="CDC WISQARS Mobile Application Case Study"
        description="Learn more about the custom mobile applications and analytics framework we developed for the
          Centers for Disease Control and Prevention (CDC)."
        image={CdcBanner}
      />

      <div>
        <img
          className="responsive-image w-100 border-bottom-banner"
          alt="CDC Wisqars iPads"
          src={CdcBanner}
        />
      </div>

      <div className="container">

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">CDC WISQARS™ Mobile Application for Injury Data</h1>
          <h5 className="text-muted">Centers for Disease Control and Prevention | 2012-2015</h5>

          <a 
            className="btn btn-white"
            href={caseStudyPdf}
            download="Wisqars_Case_Study"
            target="_blank"
            rel="noreferrer">
              Download Case Study
          </a>
        </div>

        <CaseStudyDivider>Background</CaseStudyDivider>

        <CaseStudySection>
          The Centers for Disease Control and Prevention (CDC) is one of
          the leading national public health institutes in the United States,
          and its main goal is to protect public health and safety through
          the control and prevention of disease, injury, and disability. The
          CDC focuses national attention on developing and applying disease
          control and prevention. It especially focuses its attention on
          infectious disease, foodborne pathogens, environmental health,
          occupational safety and health, health promotion, injury prevention
          and educational activities designed to improve the health of United
          States citizens. In addition, the CDC researches and provides information
          on non-infectious diseases such as obesity and diabetes and is a
          founding member of the International Association of National Public
          Health Institutes.
        </CaseStudySection>

        <CaseStudyDivider>The Problem</CaseStudyDivider>

        <CaseStudySection>
          <p>
            CDC’s WISQARS™ (Web-based Injury Statistics Query and Reporting System)
            is an interactive, online database that provides fatal and nonfatal injury,
            violent death, and cost of injury data from a variety of trusted sources.
            Researchers, members of the media, public health professionals, and the
            public can all use WISQARS™ data to learn more about the public health
            and economic burden associated with unintentional and violence-related
            injury in the United States.
          </p>
          <p>
            They wanted to create a mobile application that provided their injury data
            to researchers and other data consumers via the following mobile platforms:
            iPad, iPhone, and Android. The WISQARS™ team had already identified the data
            they were interested in displaying, but the size of their data simply was not
            practical for delivery to a mobile platform.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Process</CaseStudyDivider>

        <CaseStudySection>
          <p>
            After analyzing the problems faced by the WISQARS™ team, we identified the two
            key components of a proper solution. First, because the CDC wanted the mobile
            applications to work with or without an active data connection, we had to develop
            a method to reduce the volume of large amounts of data and preserve viability on
            mobile devices without compromising the data’s value or validity. Secondly,
            because these applications would be available to the general public, we had to
            implement a highly intuitive series of visualizations to present the data effectively
            to all types of users.
          </p>
          <p>
            We tackled the data compression first. As part of the extraction process from the source database, we
            worked with the WISQARS™ data experts to develop aggregate levels that allowed for a significant
            reduction in the overall size of the data. Once the aggregated reporting data was prepared, we exported
            it to SQLite for further compression and then included the compressed data as a component of the app,
            eliminating the need for a data connection..
          </p>
          <p>
            After solidifying the data, we began the process of crafting visualizations. Once again we worked with
            the data experts at the CDC, this time to determine which models were the most valuable. Then, equipped
            with a more firm understanding of the model specifics, we proposed several different data visualizations
            that we deemed most appropriate for conveying the stories behind the data.
          </p>
        </CaseStudySection>

        <CaseStudyImage className="pb-3" src={CdcMockups}>
          Mockup concepts from the collaborative sessions with the CDC WISQARS™ team
        </CaseStudyImage>

        <CaseStudySection>
          These proposals kicked off a cycle of active collaboration. The WISQARS™ team would vet the
          recommendations, provide feedback, and then we would iterate back through this process until all visuals
          were accepted by both the CDC data experts and our own user experience specialists. This collaborative
          effort significantly improved the veracity of the interfaces without sacrificing the ease of use that
          mobile users expect.
        </CaseStudySection>

        <CaseStudyDivider>The Result</CaseStudyDivider>

        <CaseStudySection>
          We chose to utilize Apache Flex for the actual mobile application build because it provided
          cross-platform mobile support and allowed us to take advantage of previous work we had done with the
          CDC. We were able to produce a highly-interactive application that cost significantly less to develop
          than if we were to generate a unique codebase for each platform (about 90% of the code base was common
          to the iPhone, iPad, and Android applications).
        </CaseStudySection>

        <CaseStudyImage src={CdcMobileLandscape}/>

        <CaseStudySection>
          Because we had to develop fully native applications for the three target platforms, we made sure to
          utilize common navigation elements and follow the established interface conventions of that time.
        </CaseStudySection>

        <CaseStudyImage className="pb-3" src={CdcTabletDetail}>
          Using Apache Flex allowed us to efficiently create a robust, cross-platform application
        </CaseStudyImage>

        <CaseStudySection>
          In order to keep the applications up to date, we also developed a back-end analytics framework that
          would run server-side and output a new version of the mobile database. The mobile apps connect to this
          framework, scan for updates periodically, and if new data is available they consume the new database
          generated from our analytic methods.
        </CaseStudySection>

        <CaseStudyImage src={CdcPerspective} size="wide"/>

        <CaseStudySection>
          <p>
            Furthermore, we developed the code base to ensure ease of maintenance. It was constructed in such a way
            that CDC developers could carry it forward and update the source without further assistance from us.
            They have taken full advantage of this valuable feature and enacted some of their own subsequent
            modifications to the WISQARS™ application.
          </p>

          <p>
            The free CDC WISQARS™ application can be found in the App Store for both iPhone and iPad as well as the
            Google Play Store for Android.
          </p>
        </CaseStudySection>

      </div>

      <ContactUs>
        Want to see what we can build for you?
      </ContactUs>

    </div>
  );
}
