import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import EdlScreen from '../../assets/images/edl/edl-screen.png';

import caseStudyPdf from '../../assets/documents/edl/case-study.pdf';

export default function PortfolioEdl() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#40454b" borderColor="#000000">

      <ResponsiveImage
        alt="Bio-preparedness data visualizations, symptom search results, bar chart, pie chart, word cloud"
        className="pb-5"
        image={EdlScreen}
      />

      <PortfolioDescription
        title="US Census Bureau: Enterprise Data Lake"
        description="The Enterprise Data Lake (EDL) serves as a critical project for the US Census Bureau and provides quite a few vital features for the organization. When they decided to develop their architecture independent of SAS, BNL created an enterprise-wide analytics platform running in the AWS Cloud with SAS as a critical element, enabling Census to operate more efficiently than ever before."
        demo="Request Demo"
        demoLink='https://bnlconsulting.com/contact'
        demoLinkText='Contact Us'
        learnMoreLink="/case-studies/edl"
        learnMoreText="View Case Study"
        learnMoreDownloadLink={caseStudyPdf}
        learnMoreDownloadName="Edl-Case-Study"
        createdForLink="https://www.census.gov/"
        createdFor="United States Census Bureau"
      />

    </PortfolioSection>
  );
}
