import React from 'react';

import Disqus from 'disqus-react';
import PropTypes from 'prop-types';

function BlogDisqus(props) {
  const {className, post} = props;

  return (
    <div className={className}>
      {post && (
        <Disqus.DiscussionEmbed
          shortname="bnlconsultingcom"
          config={{
            url: window.location.href,
            identifier: post.slug,
            title: post.title
          }}
        />
      )}
    </div>
  );
}

BlogDisqus.propTypes = {
  className: PropTypes.string,
  post: PropTypes.shape({
    title: PropTypes.string,
    slug: PropTypes.string
  }).isRequired
};

export default BlogDisqus;


