import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import cdcIcon from '../../assets/images/cdc/cdc-icon.png';
import cdcIpadsSm from '../../assets/images/cdc/cdc-ipads-sm.png';
import cdcIpadsMd from '../../assets/images/cdc/cdc-ipads-md.png';
import cdcIpadsLg from '../../assets/images/cdc/cdc-ipads-lg.png';
import cdcIpads from '../../assets/images/cdc/cdc-ipads-xl.png';
import cdcWireframeSm from '../../assets/images/cdc/cdc-wireframe-sm-2.png';
import cdcWireframeMd from '../../assets/images/cdc/cdc-wireframe-md-2.png';
import cdcWireframeLg from '../../assets/images/cdc/cdc-wireframe-lg-2.png';
import cdcWireframe from '../../assets/images/cdc/cdc-wireframe-xl-2.png';
import cdcIphonesSm from '../../assets/images/cdc/cdc-iphones-sm.png';
import cdcIphonesMd from '../../assets/images/cdc/cdc-iphones-md.png';
import cdcIphonesLg from '../../assets/images/cdc/cdc-iphones-lg.png';
import cdcIphones from '../../assets/images/cdc/cdc-iphones-xl.png';

import caseStudyPdf from '../../assets/documents/wisqars/case-study.pdf';

export default function PortfolioCdc() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#2471b3" borderColor="#444444">

      <div className="pb-5 col-sm-6 offset-sm-3 col-4 offset-4">
        <img
          className="m-auto icon-image"
          alt="CDC Icon Mockup"
          src={cdcIcon}
        />
      </div>

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">Web-based Injury Statistics Query and Reporting System: Modernization Effort</h1>
          <h2 className="h5">After nearly a decade of serving The CDC’s Injury Prevention Center, our original WISQARS visualization required a few technical advancements. To maintain WISQARS’s functionality for the CDC, our experts overhauled the cost module, refreshed the OS, enhanced visualization, implemented cloud computing, and much more.</h2>
        </div>  

      <ResponsiveImage
        className="pb-5"
        alt="CDC Wisqars mobile app iPad screens perspective"
        image={cdcIpads}
        largeImg={cdcIpadsLg}
        mediumImg={cdcIpadsMd}
        smallImg={cdcIpadsSm}
      />

      <PortfolioDescription
        title="CDC WISQARS - iPad"
        description="A hybrid mobile application built to expose publicly available population health
        data across multiple dimensions such that an analyst could use as a tool to identify insights in related data."
        learnMoreText="View Case Study"
        learnMoreLink="/case-studies/cdc-wisqars-mobile"
        demo="Download"
        demoLink="https://itunes.apple.com/us/app/wisqars-mobile/id793979134?mt=8"
        demoLinkText="App Store"
        createdFor="Centers for Disease Control and Prevention"
        createdForLink="https://www.cdc.gov/"
        learnMoreDownloadLink={caseStudyPdf}
        learnMoreDownloadName="Wisqars-Case-Study"
      />

      <ResponsiveImage
        className="pt-5"
        alt="CDC Wisqars mobile app wireframes and design"
        image={cdcWireframe}
        largeImg={cdcWireframeLg}
        mediumImg={cdcWireframeMd}
        smallImg={cdcWireframeSm}
      />

      <ResponsiveImage
        className="p-5"
        alt="CDC Wisqars mobile app iphone screens"
        image={cdcIphones}
        largeImg={cdcIphonesLg}
        mediumImg={cdcIphonesMd}
        smallImg={cdcIphonesSm}
      />

      <PortfolioDescription
        title="CDC WISQARS - iPhone"
        description="An iPhone version of the application above for the Centers for Disease Control and Prevention.
        A data visualization for millions of cause of death statistics, in a smaller, phone-specific design."
        learnMoreText="View Case Study"
        learnMoreLink="/case-studies/cdc-wisqars-mobile"
        demo="Download"
        demoLink="https://itunes.apple.com/us/app/wisqars-mobile/id793979134?mt=8"
        demoLinkText="App Store"
        createdFor="Centers for Disease Control and Prevention"
        createdForLink="https://www.cdc.gov/"
        learnMoreDownloadLink={caseStudyPdf}
        learnMoreDownloadName="Wisqars-Case-Study"
      />

    </PortfolioSection>
  );
}
