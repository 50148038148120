import React, {useEffect} from 'react';

import bnlSliceA from '../../assets/images/slide/bnl-slice-a.png';
import bnlSliceB from '../../assets/images/slide/bnl-slice-b.png';
import bnlSliceC from '../../assets/images/slide/bnl-slice-c.png';
import bnlSliceD from '../../assets/images/slide/bnl-slice-d.png';
import bnlSliceTitle from '../../assets/images/slide/bnl-slice-title.png';
import bnlSliceSubtitle from '../../assets/images/slide/bnl-slice-subtitle.png';
import edwbDesktopXL from '../../assets/images/slide/edwb-desktop-xl.png';
import edwbPhoneXL from '../../assets/images/slide/edwb-phone-xl.png';

import twitterIpadA from '../../assets/images/slide/twitter-ipad-a.png';
import twitterIpadB from '../../assets/images/slide/twitter-ipad-b.png';

import iphoneInsightsA from '../../assets/images/slide/iphone-x-insights-a.png';
import iphoneOpioid from '../../assets/images/slide/iphone-x-opioid.png';
import iphoneInsightsB from '../../assets/images/slide/iphone-x-insights-b.png';
import iphoneHockey from '../../assets/images/slide/iphone-x-hockey.png';
import uncScreen from '../../assets/images/slide/unc-screen-xl.png';

import cdcIpad from '../../assets/images/slide/cdc-ipad.png';
import cdcPhone from '../../assets/images/slide/cdc-phone.png';

import arrowLeft from '../../assets/images/slide/arrow-left.png';
import arrowRight from '../../assets/images/slide/arrow-right.png';

import './LayerSlider.css';

export default function LayerSlider() {

  useEffect(() => {
    if (window.$) {
      window.$('#layerslider').layerSlider({
        responsive: true,
        responsiveUnder: 1280,
        layersContainer: 1280,
        skin: 'noskin',
        hoverPrevNext: false,
        pauseOnHover: false,
        skinsPath: 'layerslider/skins/'
      });
    }
  });

  return (
    <div id="full-slider-wrapper" className="slider-wrapper">
      <div id="layerslider" style={{width: '100vw', height: '640px', minHeight: '240px'}}>

        {/* BNL Logo Slide : 1 */}
        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;slidedelay:3000;">

          <img className="ls-l" width="50px" height="200px"
               style={{top: 200, left: 290, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:0;durationin:500;delayin:1200;rotateyin:-90;transformoriginin:right 50% 0;offsetxout:0;durationout:750;rotateyout:90;transformoriginout:right 50% 0;"
               src={bnlSliceA} alt=""/>
          <img className="ls-l" width="50px" height="200px"
               style={{top: 200, left: 340, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:0;durationin:500;delayin:900;rotateyin:-90;transformoriginin:right 50% 0;offsetxout:0;durationout:750;rotateyout:90;transformoriginout:right 50% 0;"
               src={bnlSliceB} alt=""/>
          <img className="ls-l" width="50px" height="200px"
               style={{top: 200, left: 390, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:0;durationin:500;delayin:600;rotateyin:-90;transformoriginin:right 50% 0;offsetxout:0;durationout:750;rotateyout:90;transformoriginout:right 50% 0;"
               src={bnlSliceC} alt=""/>
          <img className="ls-l" width="50px" height="200px"
               style={{top: 200, left: 440, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:0;durationin:500;delayin:300;rotatexin:90;transformoriginin:50% bottom 0;offsetxout:0;durationout:750;rotateyout:90;skewyout:1;transformoriginout:right 50% 0;"
               src={bnlSliceD} alt=""/>

          <img className="ls-l" width="476px" height="216px"
               style={{top: 191, left: 529, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:400;durationin:500;delayin:300;offsetxout:400;"
               src={bnlSliceTitle} alt=""/>
          <img className="ls-l" width="712px" height="79px"
               style={{top: 425, left: 291, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:0;durationin:2000;delayin:1600;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% top 0;offsetxout:600;"
               src={bnlSliceSubtitle} alt=""/>

          <img className="ls-l ls-linkto-6" width="35px" height="35px"
               style={{top: 570, left: 576, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;"
               src={arrowLeft} alt=""/>
          <img className="ls-l ls-linkto-2" width="35px" height="35px"
               style={{top: 570, left: 666, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:50;delayin:1000;offsetxout:50;"
               src={arrowRight} alt=""/>
        </div>

        {/* EDWB Dashboard Slide : 2 */}
        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;slidedelay:7000;">
          <img className="ls-l" width="660" height="600" style={{top: 90, left: '55%', whiteSpace: 'nowrap'}}
               data-ls="offsetxin:600;durationin:2000;offsetxout:-600;" src={edwbDesktopXL}
               alt=""/>
          <img className="ls-l" width="339" height="500" style={{top: 240, left: '80%', whiteSpace: 'nowrap'}}
               data-ls="offsetxin:1200;durationin:2000;offsetxout:-1000;"
               src={edwbPhoneXL} alt=""/>

          <p className="ls-l slider-title slider-title-b"
             style={{top: 310, left: 70, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;">
            ATTRACTIVE INSIGHTS
          </p>

          <p className="ls-l slider-subtitle slider-subtitle-b"
             style={{top: 350, left: 110, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:2000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% top 0;offsetxout:-400;">
            custom dashboards
          </p>
          <img className="ls-l ls-linkto-1" width="35px" height="35px"
               style={{top: 420, left: 180, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;" src={arrowLeft}
               alt=""/>
          <img className="ls-l ls-linkto-3" width="35px" height="35px"
               style={{top: 420, left: 280, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:50;delayin:1000;offsetxout:50;"
               src={arrowRight} alt=""/>
        </div>

        {/*  Twitter Slide : 3 */}
        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;slidedelay:7000;">

          <img className="ls-l" width="552" height="425" style={{top: 122, left: 180, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:-300;durationin:2000;offsetxout:-400;" src={twitterIpadA}
               alt=""/>
          <img className="ls-l" width="610" height="470" style={{top: 100, left: 500, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:400;durationin:2000;offsetxout:500;"
               src={twitterIpadB} alt=""/>

          <p className="ls-l slider-title slider-title-c"
             style={{top: 535, left: 250, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-400;durationout:1000;">
            BIG DATA
          </p>

          <p className="ls-l slider-subtitle slider-subtitle-c"
             style={{top: 575, left: 245, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:2000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% top 0;offsetxout:-600;">
            no big deal
          </p>

          <img className="ls-l ls-linkto-2" width="35px" height="35px"
               style={{top: 585, left: 754, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;" src={arrowLeft}
               alt=""/>
          <img className="ls-l ls-linkto-4" width="35px" height="35px"
               style={{top: 585, left: 844, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:50;delayin:1000;offsetxout:50;"
               src={arrowRight} alt=""/>
        </div>

        {/*  Insights Phone Slide : 4 */}
        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;slidedelay:7000;">

          <img className="ls-l" width="250" height="512"
               style={{top: 220, left: '17%', whiteSpace: 'nowrap', opacity: 0.95}}
               data-ls="offsetyin:450;offsetxin:0;delayin:0;durationin:1400;offsetyout:500;offsetxout:0;"
               src={iphoneInsightsA} alt=""/>
          <img className="ls-l" width="250" height="512"
               style={{top: 220, left: '39%', whiteSpace: 'nowrap', opacity: 0.95}}
               data-ls="offsetyin:450;offsetxin:0;delayin:200;durationin:1400;offsetyout:500;offsetxout:0;"
               src={iphoneOpioid} alt=""/>
          <img className="ls-l" width="250" height="512"
               style={{top: 220, left: '61%', whiteSpace: 'nowrap', opacity: 0.95}}
               data-ls="offsetyin:450;offsetxin:0;delayin:400;durationin:1400;offsetyout:500;offsetxout:0;"
               src={iphoneInsightsB} alt=""/>
          <img className="ls-l" width="250" height="512"
               style={{top: 220, left: '83%', whiteSpace: 'nowrap', opacity: 0.95}}
               data-ls="offsetyin:450;offsetxin:0;delayin:600;durationin:1400;offsetyout:500;offsetxout:0;"
               src={iphoneHockey} alt=""/>

          <p className="ls-l slider-title slider-title-a"
             style={{top: 110, left: 500, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;">
            DATA ON THE GO
          </p>

          <p className="ls-l slider-subtitle slider-subtitle-a"
             style={{top: 150, left: 520, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:2000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% top 0;offsetxout:200;">
            mobile solutions
          </p>
          <img className="ls-l ls-linkto-3" width="35px" height="35px"
               style={{top: 112, left: 440, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:-50;delayin:1600;offsetxout:-200;"
               src={arrowLeft} alt=""/>
          <img className="ls-l ls-linkto-5" width="35px" height="35px"
               style={{top: 112, left: 800, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:50;delayin:1600;offsetxout:200;"
               src={arrowRight} alt=""/>
        </div>

        {/*  UNC Slide : 5 */}
        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;slidedelay:7000;">

          <img className="ls-l" width="900" height="602" style={{top: 100, left: 300, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:0;offsetyin:400;durationin:2000;offsetxout:0;offsetyout:500;"
               src={uncScreen} alt=""/>

          <p className="ls-l slider-title slider-title-a" style={{top: 310, left: 60, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:-200;durationout:1000;">
            LESS SEARCHING
          </p>

          <p className="ls-l slider-subtitle slider-subtitle-a"
             style={{top: 350, left: 85, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:2000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% top 0;offsetxout:-400;">
            more discovery
          </p>
          <img className="ls-l ls-linkto-4" width="35px" height="35px"
               style={{top: 420, left: 130, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;" src={arrowLeft}
               alt=""/>
          <img className="ls-l ls-linkto-6" width="35px" height="35px"
               style={{top: 420, left: 220, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:50;delayin:1000;offsetxout:50;" src={arrowRight}
               alt=""/>
        </div>

        {/*  CDC Mobile Slide : 6 */}
        <div className="ls-slide" data-ls="transition2d:1;timeshift:-1000;slidedelay:7000;">

          <img className="ls-l" width="680" height="525" style={{top: 170, left: 100, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:-300;durationin:2000;offsetxout:-400;" src={cdcIpad}
               alt=""/>
          <img className="ls-l" width="257" height="500" style={{top: 230, left: 750, whiteSpace: 'nowrap'}}
               data-ls="offsetyin:400;offsetxin:0;durationin:2000;offsetyout:500;offsetxout:0;"
               src={cdcPhone} alt=""/>

          <p className="ls-l slider-title slider-title-a"
             style={{top: 140, left: 950, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:1500;easingin:easeOutElastic;rotatexin:-90;transformoriginin:50% top 0;offsetxout:200;durationout:1000;">
            USER CENTERED
          </p>

          <p className="ls-l slider-subtitle slider-subtitle-a"
             style={{top: 180, left: 946, whiteSpace: 'nowrap'}}
             data-ls="offsetxin:0;durationin:2000;delayin:2000;easingin:easeOutElastic;rotatexin:90;transformoriginin:50% top 0;offsetxout:400;">
            data driven design
          </p>
          <img className="ls-l ls-linkto-5" width="35px" height="35px"
               style={{top: 250, left: 1030, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:-50;delayin:1000;offsetxout:-50;" src={arrowLeft}
               alt=""/>
          <img className="ls-l ls-linkto-1" width="35px" height="35px"
               style={{top: 250, left: 1120, whiteSpace: 'nowrap'}}
               data-ls="offsetxin:50;delayin:1000;offsetxout:50;"
               src={arrowRight} alt=""/>
        </div>

      </div>
    </div>
  );
}

LayerSlider.propTypes = {};
