import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import CaseStudyDivider from '../../components/CaseStudies/CaseStudyDivider';
import CaseStudySection from '../../components/CaseStudies/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudies/CaseStudyImage';

import WorldAwareBanner from '../../assets/images/world-aware/world-aware-banner.jpg';
import WorldAwareScreenUI from '../../assets/images/world-aware/world-aware-screen.png';

export default function WorldAwareCompanionApplication() {
  return (
    <div className="CdcWisqars">

      <MetaData
        title="WorldAware Companion Application Case Study"
        description="Learn more about the custom mobile applications and analytics framework we developed for the
          Centers for Disease Control and Prevention (CDC)."
        image={WorldAwareBanner}
      />

      <div>
        <img
          className="responsive-image w-100 border-bottom-banner"
          alt="CDC Wisqars iPads"
          src={WorldAwareBanner}
        />
      </div>

      <div className="container">

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">WorldAware Companion Application Case Study</h1>
        </div>

        <CaseStudyDivider>Overview</CaseStudyDivider>

        <CaseStudySection>
        WorldAware has been the premier integrated
        risk management firm since its inception in 2000.
        They specialize in empowering clients with the
        best intelligence and insights available to ensure
        they can operate globally with confidence. Their
        innovative solutions enable multinational organizations to prepare for, monitor, and respond to
        potential threats. This level of readiness allows
        WorldAware’s clients to continually ensure the
        safety and security of their people, facilities, suppliers, and information.
        </CaseStudySection>

        <CaseStudyDivider>Challenge</CaseStudyDivider>

        <CaseStudySection>
          <p>
            Due to the nature of WorldAware’s operations, they required a hybrid web application tailored to
            their unique needs. WorldAware was looking to satisfy existing customers, expand their clientele,
            and win more business by expanding the flagship application features and offerings. WorldAware
            had also acquired a variety of technology and applications over the course of a few mergers and
            were looking to integrate all of their products into a single unified platform.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Process</CaseStudyDivider>

        <CaseStudySection>
        <ul>
            <li>
              <p>
                We architected a modern React/Redux application framework with a modular design to allow
                for rapid development and code reuse.
              </p>
            </li>
            <li>
              <p>
                We implemented team development best practices that included code style enforcement, test
                coverage thresholds, unit and integration tests, and code review as part of an automated CI/
                CD process and ever-present quality safeguards.
              </p>
            </li>
            <li>
              <p>
                Took advantage of open source libraries and component sets as much as possible to accelerate the rate of feature development and developed a consistent user experience and design
                language throughout the product.
              </p>
            </li>
            <li>
              <p>
                Enhanced a complex world map feature with modern geospatial tools and techniques, allowing
                for the simultaneous display of dozens of layers and thousands of points and polygons.
              </p>
            </li>
          </ul> 
        </CaseStudySection>

        <CaseStudyDivider>Our Solution</CaseStudyDivider>

        <CaseStudySection>
          <p>
            BNL designed and developed WorldAware’s
            next-generation customer-facing web application, Companion. Companion gives organiza-
            tions the ability to monitor events worldwide
            and quickly communicate with their people
            who may be impacted. A robust query interface, with advanced geospatial searching and
            visualization, was developed to identify people
            and assets that are impacted by events around
            the world.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>Technology We Used</CaseStudyDivider>

        <CaseStudySection>
          <p>
            Web application development includes three
            primary areas. One, the development of a new
            React web application to replace legacy Angular applications. Two, the maintenance of legacy
            Angular applications. Three, the development
            and maintenance of Node.js middleware. The
            mobile application development includes backend development of Google Cloud services using Node.js and Google Firestore real-time da-
            tabase.
          </p>
        </CaseStudySection>

        <CaseStudySection>
        <ul>
            <li>
              <p>
              Figma for design and prototyping
              </p>
            </li>
            <li>
              <p>
              Node.js / Express web server with Docker, Redis, and AWS ECS and SQS for “back-end-for-frontend” proxy services.
              </p>
            </li>
            <li>
              <p>
              React.js, Redux, React Router, Ant Design, for application architecture
              </p>
            </li>
            <li>
              <p>
              Mapbox, Geojson, Turf.js, and SuperCluster for geospatial visualization
              </p>
            </li>
          </ul> 
        </CaseStudySection>

        <CaseStudyDivider>Results</CaseStudyDivider>

        <CaseStudyImage src={WorldAwareScreenUI} size="wide" />

        <CaseStudySection>
        Companion’s development was the most challenging task during our engagement with WorldAware,
        ultimately leading to the greatest success. Thanks to our overhaul, WorldAware now possesses a
        timely, predictive risk intelligence to help organizations make the right decisions for their people
        and operations. Companion quickly became the central platform of WorldAware’s risk management
        solutions-combining the three most important pillars of their mission: intelligence, asset exposure,
        and communication.
        </CaseStudySection>
      </div>

      <ContactUs>
        Want to see what we can build for you?
      </ContactUs>

    </div>
  );
}
