import React from 'react';

import AWS from '../../assets/images/partners/aws.png';
import Cloudera from '../../assets/images/partners/cloudera.png';
import Adobe from '../../assets/images/partners/adobe.png';
import IBM from '../../assets/images/partners/ibm.png';
import SAS from '../../assets/images/partners/sas.png';
import Magpie from '../../assets/images/partners/magpie-logo.png';
import Azure from '../../assets/images/partners/azure.png';
import DataBricks from '../../assets/images/partners/databricks.png';

import NCMABadge from '../../assets/images/partners/NCMA_Badge.png';
import ELEVBadge from '../../assets/images/partners/ELEV_Badge.png';

const AWS_URL = 'https://partners.amazonaws.com/partners/001E000001LiNsmIAF/BNL%20Consulting';
const SAS_URL = 'https://www.sas.com/en_us/partners/find-a-partner/alliance-partners/us-partners/bnl.html';
const CLOUDERA_URL = 'https://www.cloudera.com/partners.html';
const ADOBE_URL = 'https://partners.adobe.com/exchangeprogram/creativecloud/fragments/partners/a1X14000009trlpEAA.html';
const IBM_URL = 'https://www.ibm.com/partnerworld/bpdirectory/partner/ee1eebe0-92e8-11e5-82f1-020031000011/bnl-consulting-llc/8wz0x/';
const MAGPIE_URL = 'https://www.magpieanalytics.com/';
const DATABRICKS_URL = 'https://www.databricks.com/';
const AZURE_URL = 'https://azure.microsoft.com/en-us';

export default function HomePartners() {
  return (
    <div className="background-light-gray text-center">

      <div className="container py-2">

      <div>

        <div className="d-inline-block p-1 p-md-4">
          <img src={NCMABadge} alt="NCMA 2023 Badge" height={180}/>
        </div>

        <div className="d-inline-block p-3">
          <img src={ELEVBadge} alt="ELEV 2024 Badge" height={180}/>
        </div>

      </div>

        <small className="text-uppercase font-weight-bold">
          We're proud to partner with
        </small>

        <div className="mt-3">

          <div className="d-inline-block p-3 p-md-4">
            <a href={AWS_URL} target="_blank" rel="noreferrer">
              <img className="img-grayscale" src={AWS} alt="AWS Partner Logo" height={30}/>
            </a>
          </div>

          <div className="d-inline-block p-3 p-md-4">
            <a href={CLOUDERA_URL} target="_blank" rel="noreferrer">
              <img className="img-grayscale" src={Cloudera} alt="Cloudera Partner Logo" height={30}/>
            </a>
          </div>

          <div className="d-inline-block p-3 p-md-4">
            <a href={ADOBE_URL} target="_blank" rel="noreferrer">
              <img className="img-grayscale" src={Adobe} alt="Adobe Partner Logo" height={30}/>
            </a>
          </div>

          <div className="d-inline-block p-3 p-md-4">
            <a href={IBM_URL} target="_blank" rel="noreferrer">
              <img className="img-grayscale" src={IBM} alt="IBM Partner Logo" height={30}/>
            </a>
          </div>

          <div className="d-inline-block p-3 p-md-4">
            <a href={MAGPIE_URL} target="_blank" rel="noreferrer">
              <img className="img-grayscale" src={Magpie} alt="Magpie Partner Logo" height={30}/>
            </a>
          </div>

          <div className="d-inline-block p-3 p-md-4">
            <a href={SAS_URL} target="_blank" rel="noreferrer">
              <img className="img-grayscale" src={SAS} alt="SAS Partner Logo" height={30}/>
            </a>
          </div>

          <div className="d-inline-block p-3 p-md-4">
            <a href={AZURE_URL} target="_blank" rel="noreferrer">
              <img className="img-grayscale" src={Azure} alt="Azure Partner Logo" height={30}/>
            </a>
          </div>

          <div className="d-inline-block p-3 p-md-4">
            <a href={DATABRICKS_URL} target="_blank" rel="noreferrer">
              <img className="img-grayscale" src={DataBricks} alt="DataBricks Partner Logo" height={30}/>
            </a>
          </div>

        </div>

      </div>
    </div>
  );
}
