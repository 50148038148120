import React from 'react';
import PropTypes from 'prop-types';

import './ResponsiveImage.css';

export default function ResponsiveImage(props) {

    const {image, smallImg, mediumImg, largeImg, alt, className} = props;

    const defLargeImg = ((largeImg) ? largeImg : image);
    const defMediumImg = ((mediumImg) ? mediumImg : defLargeImg);
    const defSmallImg = ((smallImg) ? smallImg : defMediumImg);


    return (
        <img src={image} srcSet={`${defSmallImg} 300w, ${defMediumImg} 768w, ${defLargeImg} 1280w`} alt={alt} className={className + ' responsive-image'} />
    );
}

ResponsiveImage.propTypes = {
    image: PropTypes.string.isRequired,
    smallImg: PropTypes.string,
    mediumImg: PropTypes.string,
    largeImg: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string
};