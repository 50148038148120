import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';

export const useScrollToTop= () => {
  const {pathname, search, hash} = useLocation();

  useEffect(() => {
    // if not a hash link scroll to top
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, search, hash]);
}
