import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactForm from '../../components/ContactForm/ContactForm';
import SocialButton from '../../components/Common/SocialButton/SocialButton';
import OfficeAddress from './OfficeAddress';

import ContactHeroImage from '../../assets/images/heros/contact-hero.jpg';
import BnlLogoBlue from '../../assets/images/contact/bnl-logo-blue.svg';

import './Contact.css';

const MAP_BASE = 'https://www.google.com/maps/place';

const DC_OFFICE_MAP_LINK = MAP_BASE + '/932+Hungerford+Dr+%2317b,+Rockville,+MD+20850/@39.0978315,-77.1542535,17z';
const ATLANTA_OFFICE_MAP_LINK = MAP_BASE +'/55+Park+Square+Ct+%23203,+Roswell,+GA+30075/@34.0154761,-84.3631557,18z';
const ATHENS_OFFICE_MAP_LINK = MAP_BASE + '/770+Gaines+School+Rd,+Athens,+GA+30605/@33.9319749,-83.3334367,17z/';

export default function Contact() {
  return (
    <div className="ContactPage">

      <MetaData
        title="Contact"
        description="Interested in our data analytics, data visualization, and enterprise integration services?"
        image={ContactHeroImage}
      />

      <Hero
        image={ContactHeroImage}
        title="CONTACT"
        detail="Let's discuss solutions for your organization"
      />

      <div className="Headquarters py-5">
        <div className="container">
          <div className="row">

            <div className="col-md-6">
              <ContactForm/>

              <div className="p-5 p-sm-0"/>
            </div>

            <div className="col-md-6 map-background">

              <div className="text-center">

                <img
                  className="bnl-alt-logo"
                  src={BnlLogoBlue}
                  alt='BNL Alternate Logo Blue'
                />

                <h2 className="h4 pt-4">
                  Headquarters <br/>
                  Washington DC Office
                </h2>

                <p className="lead">
                  <a href={DC_OFFICE_MAP_LINK} target="_blank" rel="noopener noreferrer">
                    932 Hungerford Dr. Suite 17B <br/>
                    Rockville, MD 20850 <br/>
                  </a>
                  Phone: 866-201-9322
                </p>

                <ul className="list-inline mb-0 mt-2">
                  <li className="list-inline-item">
                    <SocialButton
                      type="twitter"
                      className="large"
                      link={'https://twitter.com/bnl_consulting'}
                      ariaLabel={"Twitter"}
                    />
                  </li>
                  <li className="list-inline-item px-3">
                    <SocialButton
                      type="linkedin"
                      className="large"
                      link={'https://www.linkedin.com/company/bnl-consulting'}
                      ariaLabel={"LinkedIn"}
                    />
                  </li>
                  <li className="list-inline-item">
                    <SocialButton
                      type="youtube"
                      className="large"
                      link={'https://www.youtube.com/channel/UCKpbG_0JyJPASTId5SYDMdA'}
                      ariaLabel={"YouTube"}
                    />
                  </li>
                </ul>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="background-gray py-5">
        <div className="container">
          <div id="offices" className="row">

            <div className="col-md-4">
              <OfficeAddress
                id="washington"
                locationName="Washington DC Office"
                addressLine1="932 Hungerford Dr. Suite 17B"
                addressLine2="Rockville, MD 20850"
                phone="866-201-9322"
                mapLink={DC_OFFICE_MAP_LINK}
              />
            </div>

            <div className="col-md-4">
              <OfficeAddress
                locationName="Atlanta Office"
                addressLine1="55 Park Square Ct. Suite 203"
                addressLine2="Roswell, GA 30075"
                phone="678-731-7301"
                mapLink={ATLANTA_OFFICE_MAP_LINK}
              />
            </div>

            <div className="col-md-4">
              <OfficeAddress
                id="Athens"
                locationName="Athens Office"
                addressLine1="770 Gaines School Road, Unit B"
                addressLine2="Athens, GA 30605"
                mapLink={ATHENS_OFFICE_MAP_LINK}
              />
            </div>

          </div>
        </div>
      </div>

    </div>
  );
}
