import React from 'react';
import {Carousel} from 'react-bootstrap';
import QuoteBubble from '../Common/QuoteBubble/QuoteBubble';

export default function HomeTestimonials() {

  return (
    <div className='py-5'>
      <div className="container py-md-4" style={{minHeight: 250}}>
        <div className="row">
          <Carousel controls={false} indicators={false} fade={false} interval={8000}>
            <Carousel.Item>
              <div className="col-md-8 offset-md-2">
                <QuoteBubble client="Bil Westerfield, Magpie Health Analytics">
                  The BNL team has to be one of the easiest and best teams I have worked with.
                  I always feel like the team is working hard to deliver what our customers need.
                  Whenever a challenge is placed in front of the team they figure out a way to deliver.
                  I haven’t always felt that way about technical teams. I am proud to work with BNL and
                  look forward to continuing to work with BNL in the future.
                </QuoteBubble>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="col-md-8 offset-md-2">
                <QuoteBubble client="Ben Hankins, Aitheras LLC">
                  The BNL technical staff were not only well versed in SAS and the skills necessary
                  to provide the required technical expertise; they also were true professionals capable
                  of integrating themselves seamlessly into the day-to-day operational culture
                  of the client. A unique combination of technical expertise and emotional intelligence.
                </QuoteBubble>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="col-md-8 offset-md-2">
                <QuoteBubble client="Larry Yang, SAS">
                  BNL has been supporting me on my Federal projects since 2007 without failing to consistently
                  deliver professional services of the highest quality to my Federal clients. One of my projects
                  that BNL has been supporting as the lead design/development team since 2008, was recently given
                  the Director’s Award for Innovation by a Federal client, underlining the contributions brought
                  to the project by BNL.
                </QuoteBubble>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="col-md-8 offset-md-2">
                <QuoteBubble client="Director of a Federal Health Agency">
                  BNL developed a user friendly, innovative and agile HTML5 based application to help visualize,
                  explore and analyze claims data for services rendered under the Medicare Advantage program.
                  Their ability to meet dynamic and challenging project goals within tight deadlines, coupled with
                  their technical expertise and innovative thinking is refreshing in today’s contracting environment.
                </QuoteBubble>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="col-md-8 offset-md-2">
                <QuoteBubble client="Tim Osborn, Project Manager, Crisis24">
                We were in a very difficult situation with legacy code that had to be updated before migrating to
                the cloud. BNL did an excellent job of understanding the situation and making all of the changes 
                needed for us to make our deadline with our client. Without the effort that BNL put into this project,
                our contract could have been in jeopardy. Their technical knowledge helped save the day!
                </QuoteBubble>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </div>
    </div>
  );
}
