import React from 'react';
import PropTypes from 'prop-types';

function CaseStudyDivider(props) {
  const {title, children} = props;

  return (
    <div className="CaseStudyDivider">
      <div className="row">
        <div className="col-lg-10 offset-lg-1 pb-0 pb-lg-3">
          <div className="small text-uppercase font-weight-normal">{title || children}</div>
          <hr className="mt-1"/>
        </div>
      </div>
    </div>
  );
}

CaseStudyDivider.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default CaseStudyDivider;
