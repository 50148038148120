import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import QuoteBubble from '../../components/Common/QuoteBubble/QuoteBubble';

import CyclopsHeroImage from '../../assets/images/heros/cyclops-hero.jpg';
import Desktop from '../../assets/images/cyclops/cyclops-macbook-desktop.png';
import IncreaseProductivity from '../../assets/images/cyclops/increase-productivity-icon.jpg';
import StandardizeMethods from '../../assets/images/cyclops/standardize-methods-icon.jpg';
import EaseStrain from '../../assets/images/cyclops/ease-strain-icon.jpg';
import ImproveSecurity from '../../assets/images/cyclops/improve-security-icon.jpg';
import iPhone1 from '../../assets/images/cyclops/cyclops-iphone-1.jpg';
import iPhone2 from '../../assets/images/cyclops/cyclops-iphone-2.jpg';
import iPhone3 from '../../assets/images/cyclops/cyclops-iphone-3.jpg';
import Diagram from '../../assets/images/cyclops/cyclops-diagram.png';
import MacBook from '../../assets/images/cyclops/cyclops-macbook-CMYK.png';

export default function CyclopsScheduler() {
  return (
    <div className="CyclopsScheduler">

      <MetaData
        title="Cyclops"
        description="Cyclops is a workflow automation tool to manage batch jobs in an enterprise system.
          Cyclops reduces the gap between users and the long-running batch processes that are often so critical
          for most data processing and data analytics efforts."
        image={CyclopsHeroImage}
      />

      <Hero
        image={CyclopsHeroImage}
        title={'CYCLOPS'}
        detail={'Batch job management made easy'}
      />

      <div className="container">
        <div className="row pt-5 pb-3">

          <div className="col-md-6 col-lg-5 text-center mt-2 mt-md-5 p-5 p-md-1">
            <img
              className="img-fluid rounded px-4 px-md-0 w-100"
              alt="Public Sector Graphic"
              src={Desktop}
            />
          </div>

          <div className="col-md-6 col-lg-7 p-4 p-md-5 text-center text-md-left">
            <h2 className="h3 px-md-5 pt-md-4">
              Experience the power of simplicity
            </h2>
            <p className="px-md-5">
              Organizations often encounter a significant amount
              of frustration and inefficiency when attempting to
              schedule and run batch jobs. Many jobs run for hours
              on sensitive environments. The job owner is not always
              allowed direct access to these machines, and it is
              difficult and time-consuming for these analysts to
              monitor the state of the jobs.
            </p>
          </div>

        </div>
      </div>

      <div className="background-light-gray my-5 py-2">
        <div className="container">
          <div className="row py-5">

            <div className="col-6 col-md-3 text-center">
              <img
                className="img-fluid rounded-circle px-md-2 w-75"
                alt="Increase Productivity Graphic"
                src={IncreaseProductivity}
              />
              <h3 className='h5 my-4'>
                Increase productivity
              </h3>
            </div>

            <div className="col-6 col-md-3 text-center">
              <img
                className="img-fluid rounded-circle px-md-2 w-75"
                alt="Standardize Methods Graphic"
                src={StandardizeMethods}
              />
              <h3 className='h5 my-4'>
                Standardize methods
              </h3>
            </div>

            <div className="col-6 col-md-3 text-center">
              <img
                className="img-fluid rounded-circle px-md-2 w-75"
                alt="Ease Strain on I.T. Graphic"
                src={EaseStrain}
              />
              <h3 className='h5 my-4'>
                Ease strain on I.T.
              </h3>
            </div>

            <div className="col-6 col-md-3 text-center">
              <img
                className="img-fluid rounded-circle px-md-2 w-75"
                alt="Improve Security Graphic"
                src={ImproveSecurity}
              />
              <h3 className='h5 my-4'>
                Improve security
              </h3>
            </div>

          </div>
        </div>
      </div>

      <div className="container">

        <div className="row py-3">
          <div className="col-md-8 offset-md-2 text-center">
            <h3>
              Enhance Productivity while Maintaining Security
            </h3>

            <p>
              Cyclops allows analysts to boost productivity with the
              comfort of knowing jobs are being reliably scheduled,
              executed, and monitored at all times
            </p>
          </div>
        </div>

        <div className="row py-3">
          <div className="col-lg-4 text-center mb-3">
            <img
              className="responsive-image w-75 mb-5"
              alt="Cyclopsscheduler run job screen iPhone"
              src={iPhone1}
            />
            <h4 className="h5">
              Easy access to robust job logs
            </h4>
            <p>
              Cyclops tracks the details of each step in
              an execution, providing analysts key insights.
            </p>
          </div>

          <div className="col-lg-4 text-center mb-3">
            <img
              className="responsive-image w-75 mb-5"
              alt="Cyclops scheduler email notification iPhone"
              src={iPhone2}
            />
            <h4 className="h5">
              Real-time job notifications
            </h4>
            <p>
              Being hands-free doesn't mean out of the loop.
              Keep in sync with your jobs via instant updates.
            </p>
          </div>

          <div className="col-lg-4 text-center mb-3">
            <img
              className="responsive-image w-75 mb-5"
              alt="Cyclops scheduler dashboard charts iPhone"
              src={iPhone3}
            />
            <h4 className="h5">
              Polished user experience
            </h4>
            <p>
              The software's intuitive interface allows you to
              easily manage batch jobs across all your devices.
            </p>
          </div>
        </div>
      </div>

      <div className="background-light-gray my-5 py-5">
        <div className="container">

          <h5 className="h3 py-3 text-center">
            Why Cyclops?
          </h5>

          <div className="row py-md-5">
            <div className="col-md-6 pr-md-5 text-center text-md-left">
              <h5 className="pt-2">
                Allows Self-Service Management
              </h5>
              <p>
                Cyclops makes long-running analytic, data processing,
                and ETL jobs easy to manage without IT intervention.
              </p>

              <h5 className="pt-4">
                Maintains Security
              </h5>
              <p>
                With Cyclops, data scientists no longer require
                direct access to sensitive server environments.
              </p>

              <h5 className="pt-4">
                Built from Experience
              </h5>
              <p>
                We understand that IT professionals need to control
                direct access to their servers while analysts need access
                to server-side programs to be effective. We designed Cyclops
                to serve both users needs.
              </p>
            </div>

            <div className="col-md-6">
              <img
                src={Diagram}
                alt="Cyclops Diagram"
                className="responsive-image w-100"
              />
            </div>

          </div>
        </div>
      </div>

      <div className="container">

        <h5 className="h3 pt-5 pb-3 text-center">
          Streamlining batch job management so you can get more done
        </h5>

        <div className="row">

          <div className="col-md-6 col-lg-6 order-md-last text-center mt-2 mt-md-5 p-5 p-md-1">
            <img
              className="img-fluid rounded px-4 px-md-0 w-100"
              alt="Public Sector Graphic"
              src={MacBook}
            />
          </div>

          <div className="col-md-6 col-lg-6 p-3 pr-md-5 text-center text-md-left">
            <h5 className="px-md-5 pt-md-4">
              Let Cyclops do the Heavy Lifting
            </h5>
            <p className="px-md-5">
              Organizations often experience a significant amount of
              frustration and inefficiency when attempting to schedule and
              run batch jobs. Cyclops reduces the time that data scientists
              waste on batch job management by standardizing the methodology
              with which batch jobs are scheduled and executed across the
              organization. With informative, real-time email notifications
              and robust job logs, Cyclops allows analysts to enhance their
              productivity with the comfort of knowing that their jobs are
              being reliably scheduled, executed, and monitored at all times.
            </p>
          </div>

        </div>
      </div>

      <div className="container py-5 px-md-5">
        <QuoteBubble className="py-md-5 px-md-5" client="Michael Arnett, COO BNL Consulting">
          Tools that address workload automation are either free or
          cheap and solve a general problem, such as cron, but lack
          depth/detailed functions, or they are full-service stacks
          that are very expensive and often require a fair amount of
          administration themselves. Cyclops is intended to minimize
          the additional burden on IT while addressing the biggest
          functional needs of data analysts.
        </QuoteBubble>
      </div>

      <ContactUs>
        Ready to simplify your batch job scheduling and management?
      </ContactUs>

    </div>
  );
}
