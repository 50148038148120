import React from 'react';
import PropTypes from 'prop-types';

function ImageWide(src, alt, children) {
  return (
    <div className="row pb-4 pb-lg-5">
      <div className="col col-lg-10 offset-lg-1">
        <img
          className="img-responsive w-100"
          src={src}
          alt={alt}
        />
        {children && (
          <div className="small text-muted py-3 px-5 text-center">
            {children}
          </div>
        )}
      </div>
    </div>
  );
}

function ImageStandard(src, alt, children) {
  return (
    <div className="row pb-4 pb-lg-5">
      <div className="col col-lg-8 col-print-6">
        <img
          className="img-responsive w-100"
          src={src}
          alt={alt}
        />
      </div>
      <div className="col-lg-2 col-print-4 order-lg-first order-print-first">
        {children && <>
          <div className="small text-muted d-lg-none py-3 px-5 text-center">
            {children}
          </div>
          <div className="small d-none d-lg-block border-right text-right pr-3 pb-1">
            <small className="font-weight-light">
              {children}
            </small>
          </div>
        </>}
      </div>
    </div>
  );
}

function CaseStudyImage(props) {
  const {className, size, src, alt, children} = props;

  const image = size === 'wide' ?
    ImageWide(src, alt, children) :
    ImageStandard(src, alt, children);

  return (
    <div className={className}>
      {image}
    </div>
  );
}

CaseStudyImage.propTypes = {
  className: PropTypes.string,
  src: PropTypes.node.isRequired,
  alt: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(['wide', 'default'])
};

CaseStudyImage.defaultProps = {
  size: 'default'
};

export default CaseStudyImage;
