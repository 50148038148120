import React, {useState, useEffect} from 'react';

import {useLocalStorage} from 'react-use';
import {Alert, Button} from 'react-bootstrap';

import './CookieConsent.css';

function CookieConsent() {

  const [show, setShow] = useState(false);
  const [consent, setConsent] = useLocalStorage('cookie-consent', false);

  const acceptCookies = () => {
    setConsent(true);
    setShow(false);
  };

  useEffect(() => {
    if (!consent) {
      setShow(true);
    }
  }, [consent, setShow]);

  return (
    <Alert className="text-center CookieConsent" show={show}>
      <b>Do you like cookies?</b> &nbsp; &#x1F36A; &nbsp;

      We use cookies to ensure you get the best experience on our website.

      <a href="https://cookiesandyou.com/" target="_blank" rel="noreferrer">Learn more</a>

      <Button size="sm" onClick={acceptCookies}>I Agree</Button>
    </Alert>
  );
}

export default CookieConsent;
