import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import EnterpriseIntegrationHero from '../../assets/images/heros/enterprise-integration-hero.png';
import VectorIntegration from '../../assets/images/enterprise-integration/vector-integration.jpg';
import ThreeDevice from '../../assets/images/enterprise-integration/Three-Device.jpg';
import MobileMap from '../../assets/images/enterprise-integration/Mobile-Map.jpg';
import MobileShot from '../../assets/images/enterprise-integration/Mobile-Shot-Chart.jpg';

export default function EnterpriseIntegration() {
  return (
    <div className="EnterpriseIntegration">

      <MetaData
        title="Enterprise Integration for Smarter, More Efficient Platforms"
        description="Eliminate vulnerabilities and identify gaps in integration without losing investments
        made in your organization’s current technology stacks."
        image={EnterpriseIntegrationHero}
      />

      <Hero
        image={EnterpriseIntegrationHero}
        title="ENTERPRISE INTEGRATION"
        detail="Customized systems with reduced risk and cost"
        reducedFont={true}
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          Even if you have a variety of IT subsystems managing your infrastructure, we can bring them together
          to work in harmony. We use the best-in-class cloud solutions to guarantee that information works
          seamlessly throughout your whole enterprise.
        </p>

        <p>
          We bring together cutting-edge database systems, reporting tools, and enterprise software systems to
          ensure you have all the critical information you need to run your organization.
        </p>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2 className="h3">
              System Integration Services
            </h2>
            <p>
              We integrate the power of analytics
              like <a href="https://www.sas.com/">SAS</a>
              , <a href="https://www.python.org/">Python</a>
              , and <a href="https://www.r-project.org/">R</a> with
              enterprise platforms, database management systems, and reporting tools
              such as <a href="https://www.mysql.com/">MySQL</a>
              , <a href="https://www.oracle.com/index.html">Oracle</a>
              , <a href="https://www.microstrategy.com/en">Microstrategy</a>
              , <a href="https://www.ibm.com/products/cognos-analytics">Cognos</a>
              , and other software services to help your organization run smoothly and efficiently.
            </p>
            <p>
              We'll work with you to assess your current technology stacks, identify gaps in integration, and
              design a clear roadmap for eliminating those vulnerable points. We strive to protect your investment
              in existing platforms and allow you to take advantage of new technologies, all while reducing
              your risk and costs.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL Enterprise System Integration"
                 src={VectorIntegration}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">

            <h3>
              Partners Aligned with Your Goals
            </h3>
            <p>
              There’s never a true one-size-fits-all solution for every business, that’s why we take the time
              to learn as much as possible about what makes your organization tick. Understanding your specific
              needs allows us to tailor an enterprise integration solution that aligns with your goals and strategies.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="BNL Cyclops Scheduler Enterprise Web Application"
                 src={ThreeDevice}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h3>
              Built For The Big Picture
            </h3>
            <p>
              At BNL Consulting, we want our clients to stay ahead of the curve for the long haul.
              Our enterprise integration services not only streamline processes in real-time, but also
              offer the flexibility to adapt to changes in legislation, technological advances, and other
              fluid, contextual factors. We take the time to find the ideal balance between enhancing your
              pre-existing systems and introducing new ones, as determined on a case-by-case basis. We’ll
              reduce technical debt while making sure everything plays nicely together.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL Encounter Data Responsive Dashboard Application"
                 src={MobileMap}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h3>
              Right Time, Right Place
            </h3>
            <p>
              We live in an increasingly data-driven world. To keep pace, organizations need their critical
              information delivered to the right people at the right time and in the proper format to maximize
              value. We build our enterprise integration solutions with all this in mind. Our experts have
              decades of experience building cutting-edge data analytics platforms that reliably deliver what
              you need, when and where you need it.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="BNL Responsive NBA Shooting Percentage Visualization Application"
                 src={MobileShot}/>
          </div>
        </div>

        <div className="p-5"/>

      </div>

      <ContactUs>
        Want your systems to run better, smarter, and more efficiently?
      </ContactUs>

    </div>
  );
}
