import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import ebMacbookSm from '../../assets/images/eb/eb-macbook-sm.png';
import ebMacbookMd from '../../assets/images/eb/eb-macbook-md.png';
import ebMacbookLg from '../../assets/images/eb/eb-macbook-lg.png';
import ebMacbook from '../../assets/images/eb/eb-macbook-xl.png';
import ebIcon from '../../assets/images/eb/eb-logo-xl.png';

export default function PortfolioEb() {

  return (
    <PortfolioSection backgroundColor="#f1f1f1" borderColor="#888888">

      <div className="pb-5 col-sm-6 offset-sm-3 col-8 offset-2">
        <img
          className="m-auto icon-image"
          alt="Episode Builder Title"
          src={ebIcon}
        />
      </div>

      <ResponsiveImage
        alt="Episode of care builder application screenshot"
        className="pb-5"
        image={ebMacbook}
        largeImg={ebMacbookLg}
        mediumImg={ebMacbookMd}
        smallImg={ebMacbookSm}
      />

      <PortfolioDescription
        title="Episode Builder"
        description="A comprehensive web application designed to aid in creating medical episodes to
        facilitate billing and coding. Allows the search of millions of records, and rapid drag and drop case creation."
        demo="Request Demo"
        demoLink="https://bnlconsulting.com/contact"
        demoLinkText="Contact Us"
        createdFor="Health Care Incentives Improvement Institute"
        createdForLink="https://altarum.org/news/altarum-s-innovative-value-based-payment-solution-prometheus-analytics-acquired-change-0"
      />

    </PortfolioSection>
  );
}
