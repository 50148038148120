import React from 'react';
import {Link, useLocation} from "react-router-dom";
import {NavDropdown} from "react-bootstrap";
import PropTypes from "prop-types";

function NavDropdownItemActive({to, display}) {
  const pathname = useLocation()?.pathname;

  return <NavDropdown.Item as={Link} to={to} active={pathname === `${to}`}>{display}</NavDropdown.Item>
}

NavDropdownItemActive.propTypes = {
  to: PropTypes.string,
  display: PropTypes.string,
};

export default NavDropdownItemActive;
