import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import CaseStudyDivider from '../../components/CaseStudies/CaseStudyDivider';
import CaseStudySection from '../../components/CaseStudies/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudies/CaseStudyImage';
import QuoteBubble from '../../components/Common/QuoteBubble/QuoteBubble';
import NumberList from '../../components/CaseStudies/NumberList';

import StepsHeroImage from '../../assets/images/census/census-banner.jpg';
import AnalysisPortalImage from '../../assets/images/census/analysis-portal.png';
import QueryBuilderImage from '../../assets/images/census/query-builder.png';

export default function CensusSteps() {
  return (
    <div className="EncounterData">

      <MetaData
        title="US Census Bureau StEPS II Case Study"
        description="Learn more about how we enabled the United States Census Bureau's SAS-based processing
          systems to handle robust growth and provide world-class performance."
        image={StepsHeroImage}
      />

      <div>
        <img
          className="responsive-image w-100 border-bottom-banner"
          alt="Census StEPS II Case Study Application"
          src={StepsHeroImage}
        />
      </div>

      <div className="container">

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">StEPS II</h1>
          <h5 className="text-muted">United States Census Bureau | 2009-Present</h5>
        </div>

        <CaseStudyDivider>Background</CaseStudyDivider>

        <CaseStudySection>
          <p>
            The primary mission of the United States Census Bureau is conducting the U.S.
            Census every ten years, which allocates the seats of the U.S. House of Representatives
            to the states based on their population. In addition to the decennial census, the Census
            Bureau continually conducts dozens of other censuses and surveys, including the American
            Community Survey, the U.S. Economic Census, and the Current Population Survey.
          </p>
          <p>
            Furthermore, economic and foreign trade indicators released by the federal government
            typically contain data produced by the Census Bureau. The various censuses and surveys conducted
            by the Census Bureau help allocate over $400 billion in federal funds every year and help states,
            local communities, and businesses make informed decisions.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Problem</CaseStudyDivider>

        <CaseStudySection>
          <p>
            For decades, the U.S. Census Bureau has been committed to the development and implementation of a
            generalized processing system for current economic surveys. In May 1995, they began development of the
            Standard Economic Processing System (StEPS) to replace the 16 systems previously used to process 110
            economic surveys. These 16 systems had each performed very similar operations and combined to cover many
            sectors of the economy, including but not limited to: manufacturing, retail, wholesale, services,
            transportation, and construction. Within three years, in December 1997, the first surveys began using StEPS.
            In September 2010, over fifteen years after StEPS development began, the Census Bureau retired the last of
            the 16 legacy systems.
          </p>

          <QuoteBubble className="py-5 px-md-5" client="Assistant Division Chief U.S. Census Bureau">
            Our reliance on SAS technologies required unique skill sets as we moved toward
            incorporating a newer and more diverse architecture.
          </QuoteBubble>

          <p>
            StEPS was built exclusively with SAS Institute Inc. (SAS) software, most notably SAS/AF, and StEPS data
            stores are largely SAS data sets complemented by ASCII files. Since the initial development of StEPS, SAS
            technology has advanced and SAS has embraced a new paradigm for software systems development. During this
            time, the StEPS system has grown tremendously and now services more than one hundred surveys. This growth,
            as well as its reliance on outdated and tightly coupled software, created a maintenance and development
            burden for the Census Bureau. The desire to modernize the system in order to handle growth and provide
            world-class performance compelled the StEPS team to find a way forward.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Process</CaseStudyDivider>

        <CaseStudySection>
          After thorough analysis, we at BNL concluded that it was most important to focus our attention
          on issues related to system scalability, system reliability (up-time), user concurrency, and
          hardware utilization and performance. <br/><br/>
          We then boiled those focus areas down to four concrete priorities:
        </CaseStudySection>

        <CaseStudySection>
          <NumberList items={[
            'Refactoring the system to remove dependencies on SAS/AF, a sunset technology.',
            'Creating a modern system built on best practices that decouples services, ' +
            'user interfaces,and back-end data storage and analytics',
            'Retaining as much of the existing analytics as possible (primarily Base SAS Code) ' +
            'with modest updates to avoid a complete rebuild of the analytics codebase',
            'Adopting newer enterprise technologies, such as Oracle for RDBMS, and standards around those technologies'
          ]}/>
        </CaseStudySection>

        <CaseStudySection>
          <p>
            We established a new service architecture using Java services, built with industry standard practices in
            enterprise Java development, to create a system that was scalable, more reliable, and easily tuned for
            performance. In addition to the service architecture, we built a new Windows-based user interface in Eclipse
            RCP. Using Eclipse RCP makes it easy to build Windows applications that users find familiar without needing
            to hand-code common GUI components and behavior, such as list boxes, drop lists, and drag and drop
            functionality. The RCP application was essentially a rich services client, keeping a clean separation
            between service logic and the user interface code base.
          </p>

          <QuoteBubble className="py-5 px-md-5" client="Assistant Division Chief U.S. Census Bureau">
            BNL has provided exceptional technical support in a large architectural re-design effort. The unique
            combination of technologies used in this endeavor may have been difficult to find elsewhere.
          </QuoteBubble>

          <p>
            Oracle is the RDBMS of choice at the Census Bureau so we migrated data storage to enterprise Oracle
            databases. Switching over to Oracle allowed StEPS II to take advantage of the wealth of Oracle expertise
            already present at the Census Bureau, making it a much more maintainable solution. We also adopted several
            high-performance and high-availability technologies for the services and analytics tiers. Using COTS
            (Commercial-Off-The-Shelf) software that meets industry standards for high performance and availability made
            those tiers more stable, more scalable, and more easily extended to handle increased load.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Result</CaseStudyDivider>

        <CaseStudySection>
          Our service-based architecture dramatically improved StEPS’ reliability and performance, thanks to the new
          methods for addressing fail-overs, load-balancing, and processing capacity based on enterprise Java standards.
        </CaseStudySection>

        <CaseStudyImage src={AnalysisPortalImage}>
          Our analytics harness allowed the StEPS team to focus on refinement rather than recreation
        </CaseStudyImage>

        <CaseStudySection>
          The new and improved analytics harness provided a method for moving existing SAS code from the previous
          version into the new system with minimal modification. This allowed the analytics development team to focus on
          refining this existing code base and expanding on it, rather than simply recreating the same functions in the
          new system, which was important because the existing statistical methodologies needed to remain intact.
        </CaseStudySection>

        <CaseStudyImage src={QueryBuilderImage} size="wide">
          The interface was built with standard, familiar Windows UI components to maximize usability
        </CaseStudyImage>

        <CaseStudySection>
          <p>
            The Eclipse RCP interface significantly improved the user experience as users were able to work in an
            environment comprised of familiar Windows UI components. Decoupling the user interface from the services and
            data made the system much easier to maintain for the Census Bureau’s developers. Furthermore, building a UI
            component set maintained by the Eclipse Foundation meant that support and ongoing development are based on a
            stable code foundation.
          </p>

          <p>
            The push for better maintainability didn’t end there; the new system removed all dependencies on obsolete
            technologies, and we made sure the newer technologies included enterprise versions of SAS and Oracle
            platforms in which the Census Bureau has expertise. Additionally, the loosely coupled service architecture
            makes the inclusion of new technologies easier moving forward, so it should be awhile before StEPS III is
            needed.
          </p>

          <QuoteBubble className="py-5 px-md-5" client="Larry Yang, SAS Institute">
            One of my projects that BNL Consulting has been supporting as lead design/development team since 2009 [StEPS
            II] was recently given the Director’s Award for Innovation by a Federal client, underlining the
            contributions brought to the project by BNL.
          </QuoteBubble>

        </CaseStudySection>

      </div>

      <ContactUs>
        Want to see what we can build for you?
      </ContactUs>

    </div>
  );
}
