import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import GhostContentAPI from '@tryghost/content-api';
import {Link} from 'react-router-dom';

import LoadingIndicator from '../Common/LoadingIndicator/LoadingIndicator';

const ghost = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_URL,
  key: process.env.REACT_APP_GHOST_KEY,
  version: 'v3'
});

function BlogFeatureCards(props) {
  const {className} = props;

  const [loading, setLoading] = useState(true);
  const [featuredPosts, setFeaturedPosts] = useState([]);

  useEffect(() => {
    ghost.posts.browse({limit: 3, filter: 'featured:true'})
      .then(result => setFeaturedPosts(result))
      .catch(() => setFeaturedPosts([]))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={className}>

      <LoadingIndicator loading={loading}/>

      <div className="row row-eq-height d-none d-md-flex">

        {featuredPosts.map(post => (
          <div key={post.slug} className="col-4 pb-5">
            <Link to={`/blog/${post.slug}`}>
              <div className="card h-100">
                <img className="card-img-top" src={post.feature_image} alt={post.title}/>
                <div className="card-body p-3 small text-center">
                  {post.title}
                </div>
              </div>
            </Link>
          </div>
        ))}

      </div>
    </div>
  );
}

BlogFeatureCards.propTypes = {
  className: PropTypes.string
};

export default BlogFeatureCards;
