import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import hockeyIcon from '../../assets/images/hockey/hockey-logo.png';
import hockeyDevicesSm from '../../assets/images/hockey/hockey-devices-sm.jpg';
import hockeyDevicesMd from '../../assets/images/hockey/hockey-devices-md.jpg';
import hockeyDevicesLg from '../../assets/images/hockey/hockey-devices-lg.jpg';
import hockeyDevices from '../../assets/images/hockey/hockey-devices-xl.jpg';

export default function PortfolioHockey() {

  return (
    <PortfolioSection backgroundColor="#f1f1f1" borderColor="#888888">

      <div className="pb-5 col-sm-4 offset-sm-4 col-8 offset-2">
        <img
          className="m-auto icon-image"
          alt="Hockey Data Title"
          src={hockeyIcon}
        />
      </div>

      <ResponsiveImage
        alt="iPad and iPhone displaying progressive web app with hockey player shots visualized"
        className="pb-5"
        image={hockeyDevices}
        largeImg={hockeyDevicesLg}
        mediumImg={hockeyDevicesMd}
        smallImg={hockeyDevicesSm}
      />

      <PortfolioDescription
        title="Hockey Data"
        description="An internal concept web application built in React and styled with Material
        Design. It demonstrates custom visualization situations where the data may not fit a typical
        visualization, such as NHL Hockey shooting statistics."
        demo="Hosted Demo"
        demoLink="https://hockey.bnlconsulting.com/"
        demoLinkText="Sample Application"
        createdFor="BNL Consulting LLC"
        createdForLink="https://bnlconsulting.com/"
        learnMoreText="View Interactive Demo"
        learnMoreLink="https://hockey.bnlconsulting.com"
      />

    </PortfolioSection>
  );
}
