import React from 'react';
import PropTypes from 'prop-types';

import './Hero.css';

function Hero(props) {
  const {title, detail, image, reducedFont} = props;

  return (
    <div className="Hero text-center"
      style={{backgroundImage: `url(${image})`}}>
      <h1 className={reducedFont ? 'reduced' : ''}>{title}</h1>
      <p>{detail}</p>
    </div>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  detail: PropTypes.string,
  image: PropTypes.string,
  reducedFont: PropTypes.bool
};

export default Hero;
