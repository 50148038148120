import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import edwbIcon from '../../assets/images/edwb/edwb-logo.jpg';
import edwbScreensASm from '../../assets/images/edwb/edwb-screens-a-sm.png';
import edwbScreensAMd from '../../assets/images/edwb/edwb-screens-a-md.png';
import edwbScreensALg from '../../assets/images/edwb/edwb-screens-a-lg.png';
import edwbScreensA from '../../assets/images/edwb/edwb-screens-a-xl.png';
import edwbScreensBSm from '../../assets/images/edwb/edwb-screens-b-sm.png';
import edwbScreensBMd from '../../assets/images/edwb/edwb-screens-b-md.png';
import edwbScreensBLg from '../../assets/images/edwb/edwb-screens-b-lg.png';
import edwbScreensB from '../../assets/images/edwb/edwb-screens-b-xl.png';

import caseStudyPdf from '../../assets/documents/edwb/case-study.pdf';

export default function PortfolioEdwb() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#404041" borderColor="#222222">

      <div className="pb-5 col-sm-4 offset-sm-4 col-8 offset-2">
        <img
          className="m-auto icon-image"
          alt="Encounter Data Title"
          src={edwbIcon}
        />
      </div>

      <ResponsiveImage
        alt="Medicare part c encounter data workbench data visualizations"
        className="pb-5"
        image={edwbScreensA}
        largeImg={edwbScreensALg}
        mediumImg={edwbScreensAMd}
        smallImg={edwbScreensASm}
      />

      <ResponsiveImage
        alt="Medicare part c encounter data workbench bar chart, bullet chart, heat chart"
        className="pt-5 pb-5"
        image={edwbScreensB}
        largeImg={edwbScreensBLg}
        mediumImg={edwbScreensBMd}
        smallImg={edwbScreensBSm}
      />

      <PortfolioDescription
        title="Encounter Data Workbench"
        description="A responsive HTML5 web application visualizing risks and trends in Medicare Advantage
        Part C encounter data. Supports searching, filtering, and sorting of one of the most extensive
        medical claims data sets."
        learnMoreText="View Case Study"
        learnMoreLink="/case-studies/encounter-data"
        demo="Request Demo"
        demoLink="https://bnlconsulting.com/contact"
        demoLinkText="Contact Us"
        createdFor="A Federal Health Agency"
        learnMoreDownloadLink={caseStudyPdf}
        learnMoreDownloadName="Encounter-Data-Case-Study"
      />

    </PortfolioSection>
  );
}
