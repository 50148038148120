import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import opioid from '../../assets/images/opioid-poc/opioid_xl.png';
import opioidSm from '../../assets/images/opioid-poc/opioid_sm.png';
import opioidMd from '../../assets/images/opioid-poc/opioid_md.png';
import opioidLg from '../../assets/images/opioid-poc/opioid_lg.png';
import opioidPOCIcon from '../../assets/images/opioid-poc/opioid-title.png';
import caseStudyPdf from '../../assets/documents/opioid/case-study.pdf';

export default function PortfolioOpioid() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#404041" borderColor="#627f8c">

      <div className="pb-5 col-sm-6 offset-sm-3">
        <img
          className="m-auto icon-image"
          alt="American Opioid Epidemic POC Title"
          src={opioidPOCIcon}
        />
      </div>

      <ResponsiveImage
        alt="American Opioid epidemic data visualization, death rate over time, overdose deaths vs claims map"
        className="pb-5"
        image={opioid}
        largeImg={opioidLg}
        mediumImg={opioidMd}
        smallImg={opioidSm}
      />

      <PortfolioDescription
        title="American Opioid Epidemic"
        description="A functional proof of concept React web application, designed to visualize and compare
        the publicly available data for drug overdose death rates, opioid providers, and opioid prescriptions."
        demo="Hosted Demo"
        demoLink="https://opioid.bnlconsulting.com/"
        demoLinkText="Sample Application"
        learnMoreText="View Case Study"
        learnMoreLink="/case-studies/opioid"
        createdFor="Centers for Disease Control (CDC)"
        createdForLink="http://opiod.bnl-consulting.com/"
        learnMoreDownloadLink={caseStudyPdf}
        learnMoreDownloadName="Opioid-Case-Study"
      />

    </PortfolioSection>
  );
}
