import React from 'react';

import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight} from '@fortawesome/free-solid-svg-icons';

import CapitolIcon from '../../assets/images/intro/Capitol-Icon.png';
import PulseIcon from '../../assets/images/intro/Pulse-Icon.png';
import PlayIcon from '../../assets/images/intro/Play-Icon.png';
import EnterpriseIntegrationIcon from '../../assets/images/intro/Enterprise-Integration-Icon.png';
import DataVisualization from '../../assets/images/intro/Data-Visualization-Icon.png';
import DataAnalytics from '../../assets/images/intro/Data-Analytics-Icon.png';
import EnterpriseBridge from '../../assets/images/intro/Enterprise-Bridge.png';

export default function HomeFeatures() {
  return (
    <div>

      <div className="background-light-gray">
        <div className="container">
          <div className="row">

            <div className="col-md-4 col-lg-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Data Analytics Graphic"
                src={DataAnalytics}
              />
            </div>

            <div className="col-md-8 col-lg-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Data Analytics
              </h3>
              <p>
                We have all the tools and techniques to unlock your data's value. We’ll gather your
                high-quality information, organize it, and most importantly, help you use it to reach
                your goals and answer your most challenging business problems.
              </p>
              <p className="pb-2">
                Utilizing industry-leading tools like SAS, Python, and R, we’ll find precisely the right
                data trends that tell you where you stand, and how to get where you want to go.
              </p>
              <Link to="/data-analytics">
                Data Analytics Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2 order-md-last">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Data Visualization Graphic"
                src={DataVisualization}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Data Visualization
              </h3>
              <p>
                Our team of visualization experts takes a good look at your organization,
                extracts the most meaningful data, and presents it in intuitive, user-centric
                visual representations like charts and graphs, so you have up-to-the-minute information at a glance.
              </p>
              <p className="pb-2">
                With our custom Business Intelligence dashboards and comprehensive visualizations,
                you can tell your data story, solve your most complex business decisions, and make
                smarter judgments faster.
              </p>
              <Link to="/data-visualization">
                Data Visualization Services &nbsp; <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="background-light-gray">
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Enterprise Integration Graphic"
                src={EnterpriseBridge}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Enterprise Integration
              </h3>
              <p>
                We bring together cutting-edge database systems, reporting tools, and enterprise
                software systems to ensure you have the critical information you need to run your
                organization. Through visually rich applications, we deliver powerful analytics
                that empower consumers and help them make data-driven decisions.
              </p>
              <p className="pb-2">
                Even if you have a variety of IT subsystems managing your infrastructure, we can bring
                them together to work in harmony. We use the best-in-class cloud solutions to guarantee
                that information works seamlessly throughout your whole enterprise.
              </p>
              <Link to="/enterprise-integration">
                Enterprise Integration Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="background-dark-blue">
        <div className="container">
          <div className="row py-5">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2 order-md-last">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL SAS Administration Graphic"
                src={CapitolIcon}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                SAS Consulting
              </h3>
              <p>
                As long-time SAS Partners, we’ve made a name for ourselves performing services
                like administration and architecture. Our SAS-certified consultants put us in a
                uniquely advantageous position to help your business with its installation, architecture,
                development, and legacy systems modernization.
              </p>
              <p className="pb-2">
                We’re also experts in scaling the SAS platforms and can modernize their code
                to an open-source analytic tool like Python, R, or a platform like DataRobot or Snowflake.
                If we weren’t so humble, we would say that we could do all things SAS-related.
              </p>
              <Link to="/sas-consulting">
                SAS Consulting Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="background-light-gray">
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Cloud Services Graphic"
                src={EnterpriseIntegrationIcon}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Cloud Services
              </h3>
              <p>
                Our team of highly qualified AWS-certified cloud consultants has more than
                ten years of experience managing cloud technologies and demonstrated expertise
                in the whole stack of cloud computing components.
              </p>
              <p className="pb-2">
                We’ve administered numerous cloud implementations and migrations. From AWS to Azure,
                we can help with everything from migrating to the cloud, modernizing your existing
                environment, and ensuring security and compliance.
              </p>
              <Link to="/cloud-services">
                AWS Consulting Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div>
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2 order-md-last">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL System Modernization Graphic"
                src={PulseIcon}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                System Modernization
              </h3>
              <p>
                Our migration experts use tools like AWS, Azure, Docker, Kubernetes,
                and more to assess and refactor your current code, then accommodate for the
                realities of the new architecture.
              </p>
              <p className="pb-2">
                Depending on the needs of your organization, we can modernize your systems,
                migrate your architecture, or create a completely new configuration. Whatever
                system modifications you may need, we can help your enterprise achieve its legacy
                modernization or cloud migration goals through innovation.
              </p>
              <Link to="/system-modernization">
                System Modernization Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>
              </Link>
            </div>

          </div>
        </div>
      </div>

      <div className="background-light-gray">
        <div className="container">
          <div className="row">

            <div className="col-md-4 text-center mt-2 my-md-5 p-5 p-md-1 py-md-2">
              <img
                className="img-fluid rounded-xl px-4 px-md-0 w-75"
                alt="BNL Project Management Graphic"
                src={PlayIcon}
              />
            </div>

            <div className="col-md-8 p-4 p-md-5 text-center text-md-left">
              <h3>
                Project Management
              </h3>
              <p>
                We understand the importance of strong, thorough leadership and guidance to drive results.
                That’s why we provide comprehensive planning, coordinating, resource management, and more.
              </p>
              <p className="pb-2">
                No matter your project management approach, our team has the skills and knowledge to
                execute your vision. From SAFe Agile and Scrum methodologies to the software development
                lifecycle and Waterfall, we know the most efficient and effective ways to bring your plans to life.
              </p>
              {/*<Link to="/project-management">*/}
              {/*  Project Management Services <FontAwesomeIcon className="small ml-1" icon={faCaretRight}/>*/}
              {/*</Link>*/}
            </div>

          </div>
        </div>
      </div>

    </div>
  );
}
