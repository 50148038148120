import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import viperIcon from '../../assets/images/viper/viper-logo.svg';
import viperSm from '../../assets/images/viper/viper_sm.png';
import viperMd from '../../assets/images/viper/viper_md.png';
import viperLg from '../../assets/images/viper/viper_lg.png';
import viper from '../../assets/images/viper/viper_xl.png';
import steps from '../../assets/images/census/analysis-portal.png';

export default function PortfolioSteps() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#40454b" borderColor="#244035">

      <div className="pb-5 col-sm-6 offset-sm-3 col-4 offset-4">
        <img
          className="m-auto icon-image"
          alt="BNL StEPS II Title"
          src={viperIcon}
        />
      </div>

      <ResponsiveImage
        alt="Responsive designed web application displayed on several devices for the StEPS II POC"
        className="pb-5"
        image={viper}
        largeImg={viperLg}
        mediumImg={viperMd}
        smallImg={viperSm}
      />

      <div className="p-5 col-10 offset-1">
        <ResponsiveImage
          alt="BNL Java RCP Java application - StEPS II"
          className="pb-5"
          image={steps}
        />
      </div>

      <PortfolioDescription
        title="StEPS II"
        description="An enterprise Java application built upon the Eclipse Rich Client Platform. As well as a follow
        up React Web application proof of concept that offered SAS code editing and code versioning in-app."
        demo="Request Demo"
        demoLink="https://bnlconsulting.com/contact"
        demoLinkText="Contact Us"
        learnMoreText="View Case Study"
        learnMoreLink="/case-studies/census-steps"
        createdFor="United States Census Bureau"
        createdForLink="https://www.census.gov/"
      />

    </PortfolioSection>
  );
}
