import React from 'react';
import PropTypes from 'prop-types';

import {Helmet} from 'react-helmet';

const DEFAULT_URL = 'https://bnlconsulting.com/'
const DEFAULT_TITLE = 'BNL Consulting';
const DEFAULT_OGP_TYPE = 'article';
const DEFAULT_IMAGE = 'bnl.jpg';
const DEFAULT_TWITTER_CARD = 'summary_large_image';
const DEFAULT_TWITTER_SITE = '@bnl_consulting';

const DEFAULT_DESCRIPTION = 'BNL Consulting provides custom analytics platforms, interactive ' +
  'data visualizations, and enterprise integration solutions, primarily for the SAS community.';

const DEFAULT_STRUCT_DATA = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  'name': 'BNL Consulting',
  'legalName': 'BNL Consulting, LLC',
  'description': 'BNL Consulting provides custom analytics platforms, interactive data visualizations, and enterprise integration solutions, primarily for the SAS community.',
  'url': 'https://bnlconsulting.com',
  'logo': 'https://bnlconsulting.com/images/bnl-logo.png',
  'email': 'contact@bnl-consulting.com',
  'duns': '805688392',
  'address': {
    '@type': 'PostalAddress',
    'addressCountry': 'US',
    'addressLocality': 'Rockville',
    'addressRegion': 'Maryland',
    'postalCode': '20850',
    'streetAddress': '932 Hungerford Dr. Suite 17B'
  },
  'founder': {
    '@type': 'Person',
    'name': 'Rob Lill',
    'gender': 'Male',
    'jobTitle': 'President',
    'image': 'https://bnlconsulting.com/images/Robert-Lill.jpg',
    'sameAs': [
      'https://www.linkedin.com/in/roblill'
    ]
  },
  'foundingDate': '2007',
  'numberOfEmployees': {
    'minValue': 25,
    'maxValue': 50
  },
  'sameAs': [
    'https://bnl-consulting.com',
    'https://www.glassdoor.com/Overview/Working-at-BNL-Consulting-EI_IE772292.11,25.htm',
    'https://www.facebook.com/bnlconsultingllc',
    'https://www.linkedin.com/company/bnl-consulting/',
    'https://twitter.com/bnl_consulting',
    'https://www.youtube.com/channel/UCKpbG_0JyJPASTId5SYDMdA'
  ],
  'contactPoint': [
    {
      '@type': 'ContactPoint',
      'contactType': 'customer service',
      'email': 'contact@bnl-consulting.com',
      'url': 'https://bnlconsulting.com/contact'
    }
  ]
};

function createTitle(title) {
  return title ? `${title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE;
}

function createPageUrl() {
  return window.location.href;
}

function createImageUrl(image, absoluteImage) {
  return absoluteImage ? absoluteImage : DEFAULT_URL + image;
}

function MetaData(props) {
  const {title, type, description, image, absoluteImage, structuredData} = props;

  const pageTitle = createTitle(title);
  const pageUrl = createPageUrl();
  const imageUrl = createImageUrl(image, absoluteImage);

  return (
    <Helmet encodeSpecialCharacters={false}>

      <title>{pageTitle}</title>
      <meta name="description" content={description}/>

      <meta property="og:url" content={pageUrl}/>
      <meta property="og:type" content={type}/>
      <meta property="og:title" content={pageTitle}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={imageUrl}/>

      <meta name="twitter:card" content={DEFAULT_TWITTER_CARD}/>
      <meta name="twitter:site" content={DEFAULT_TWITTER_SITE}/>
      <meta name="twitter:title" content={pageTitle}/>
      <meta name="twitter:description" content={description}/>
      <meta name="twitter:image:src" content={imageUrl}/>

      {structuredData && (
        <script key="structured-data" type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      )}
    </Helmet>
  );
}

MetaData.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  image: PropTypes.string,
  absoluteImage: PropTypes.string,
  description: PropTypes.string,
  structuredData: PropTypes.object
};

MetaData.defaultProps = {
  type: DEFAULT_OGP_TYPE,
  description: DEFAULT_DESCRIPTION,
  image: DEFAULT_IMAGE,
  structuredData: DEFAULT_STRUCT_DATA
};

export default MetaData;
