import React from 'react';
import PropTypes from 'prop-types';

import LoadingBars from '../../../assets/images/misc/loading-bars.svg';

function LoadingIndicator(props) {
  const {className, loading, dimension} = props;

  return loading && (
    <div className={className}>
      <div className="text-center">
        <img src={LoadingBars} alt="Loading" width={dimension} height={dimension}/>
      </div>
    </div>
  );
}

LoadingIndicator.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  dimension: PropTypes.number
};

LoadingIndicator.defaultProps = {
  dimension: 100
};

export default LoadingIndicator;
