import React, {useState} from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle, faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {Alert, Button, Col, Form, InputGroup, Row} from 'react-bootstrap';

import './ContactForm.css';

export default function ContactForm() {
  const [hasError, setError] = useState(false);
  const [hasSent, setSent] = useState(false);
  const [isSending, setSending] = useState(false);

  const handleSubmit = (formEvent) => {
    const form = formEvent.target;

    formEvent.preventDefault();
    formEvent.stopPropagation();

    if (form.checkValidity() === false || isSending) {
      return;
    }

    const formData = new FormData(form);
    const formDataObj = Object.fromEntries(formData.entries());

    if (formDataObj.realInput !== '') {
      return;
    }

    setSending(true);

    fetch('https://api.formbucket.com/f/buk_TlZ4eFq1zqFVabjqBue9KFSG', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Content-Length': formDataObj.length
      },
      body: JSON.stringify(formDataObj)
    })
      .then(() => {
        setSent(true);
        setError(false);
      })
      .catch(() => {
        setError(true);
        setSent(false);
      })
      .finally(() => setSending(false));

    // Google-ads conversion tracking
    try {
      window.gtag_report_conversion('https://bnl-consulting.com/contact');
    } catch (e) {
      console.log('Error tagging conversion')
    }
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (e) => {
    setSelectedOption(e.target.value);
  }

  const hearAboutUsOptions = [
    { value: '', label: ''},
    { value: 'searchEngine', label: 'Search Engine (Google, Bing, etc.'},
    { value: 'socialMedia', label: 'Social Media'},
    { value: 'blog', label: 'Blog'},
    { value: 'anotherWebsite', label: 'Another Website'},
    { value: 'referral', label: 'Referral'},
    { value: 'other', label: 'Other'}
  ]

  return (
    <div className="ContactForm">
      <Form onSubmit={handleSubmit}>

        <Form.Group>
          <Form.Label className="h5 mb-2">Your Name</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>&nbsp;</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control required type="text" placeholder="Enter Name" name="name"/>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label className="h5 my-2">Your Email</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>&nbsp;</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control required type="email" placeholder="Enter Email" name="email"/>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label className="h5 my-2">Organization</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>&nbsp;</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control required maxLength="200" type="text" placeholder="Enter Organization" name="organization"/>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label className="h5 my-2" htmlFor="hearAboutUsSelect">How did you hear about us?</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>&nbsp;</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control required as="select" value={selectedOption} onChange={handleSelectChange} id="hearAboutUsSelect">
              {hearAboutUsOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Control>
          </InputGroup>
        </Form.Group>

        <Form.Group>
          <Form.Label className="h5 my-2" htmlFor="Message">Message</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>&nbsp;</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control required as="textarea" rows={6} name="message" id="Message"/>
          </InputGroup>
        </Form.Group>

        <Form.Group style={{display: 'none'}}>
          <Form.Label>Leave This Field Blank</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>&nbsp;</InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="text" name="realInput"/>
          </InputGroup>
        </Form.Group>

        <Row className="mt-md-5 my-sm-4">
          <Col md={8} sm={12} className="mb-sm-4">
            {hasSent && (
              <Alert variant="success" className="alert-contact text-center">
                <strong>
                  <FontAwesomeIcon icon={faPaperPlane}/>
                  &nbsp; Message sent successfully
                </strong>
              </Alert>
            )}

            {hasError && (
              <Alert variant="danger" className="alert-contact text-center">
                <strong>
                  <FontAwesomeIcon icon={faExclamationCircle}/>
                  &nbsp; Error sending message
                </strong>
              </Alert>
            )}

          </Col>

          <Col md={4} sm={12}>
            <Button type="submit" className="btn-dark btn-block">Submit</Button>
          </Col>
        </Row>
      </Form>

    </div>
  );
}
