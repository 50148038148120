import React from 'react';

import BrowserWork from '../../assets/images/intro/Browser-Work.png';
import MobileDevices from '../../assets/images/intro/Mobile-Devices.png';
import BrowserAnalytics from '../../assets/images/intro/Browser-Analytics.png';

const CORPORATE_PROFILE = process.env.PUBLIC_URL + '/bnl-company-profile-12_23.pdf';

export default function HomeServices() {
  return (
    <div className="container">
      <div className="row my-5">

        <div className="col-lg-4 text-center p-4">
          <img
            className="img-fluid rounded-circle px-5 px-md-4 w-75"
            alt="Data analytics services"
            src={BrowserAnalytics}
          />

          <h3 className="my-4">
            Analytics
          </h3>

          <p className="mb-4">
            The days of searching through your data to identify trends and patterns are over.
            We can analyze your data and present valuable insights in easy-to-understand terms
            with the click of a button. With tools like SAS Viya, SAS 9.4, and Python, your organization’s
            information can perform for you like never before.
          </p>

        </div>


        <div className="col-lg-4 text-center p-4">
          <img
            className="img-fluid rounded-circle px-5 px-md-4 w-75"
            alt="Applications development visualization"
            src={MobileDevices}
          />

          <h3 className="my-4">
            Applications
          </h3>

          <p className="mb-4">
            We build customized, highly-interactive data analytics applications that suit your
            enterprise’s ever-changing needs. Whether you need a mobile solution or one that
            spans multiple platforms, we can create a human-centered masterpiece that allows
            all stakeholders to make important data-driven decisions.
          </p>

        </div>


        <div className="col-lg-4 text-center p-4">
          <img
            className="img-fluid rounded-circle px-5 px-md-4 w-75"
            alt="Enterprise Integration Services"
            src={BrowserWork}
          />

          <h3 className="my-4">
            Architecture
          </h3>

          <p className="mb-4">
            We know that when you reduce your risks and costs, you can work smarter and produce
            better results. That’s why we’ve partnered with cloud providers like AWS and Azure
            to build architectures that leverage the value of legacy analytic systems while
            transforming them into modern, cloud-based systems.
          </p>

        </div>

      </div>
      
      <div className="row">
        <div className="col-lg-6 mx-auto">
          <a href={CORPORATE_PROFILE} className="btn btn-primary btn-block mx-auto" target="_blank" rel="noreferrer">
            Enable your organization with analytics expertise.
          </a>
        </div>
      </div>
    </div>
  );
}
