import React from 'react';
import PropTypes from 'prop-types';

import SocialButton from '../Common/SocialButton/SocialButton';

function AboutBio(props) {
  const {image, bio1, bio2, name, title, linkedin} = props;

  return (
    <div>
      <div className="row">
        <div className="col-12 col-md-4 text-center">
          <img className="img-fluid rounded-circle px-3 w-75" src={image} alt={name + ' headshot'}/>
          <h3 className="pt-3">{name}</h3>
          <em className="pt-3">{title}</em>
          <div className="py-3">
            <SocialButton
              type="linkedin"
              link={linkedin}
              ariaLabel={"LinkedIn"}
            />
          </div>
        </div>
        <div className="col-12 col-md-8">
          <p>{bio1}</p>
          {bio2 && (
            <p>{bio2}</p>
          )}
        </div>
      </div>
    </div>
  );
}

AboutBio.propTypes = {
  image: PropTypes.string,
  bio1: PropTypes.node,
  bio2: PropTypes.node,
  name: PropTypes.string,
  title: PropTypes.string,
  linkedin: PropTypes.string
};

export default AboutBio;
