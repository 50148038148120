import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import MetaData from '../../components/Common/MetaData/MetaData';
import PortfolioCdc from '../../components/Portfolio/PortfolioCdc';
import PortfolioEdwb from '../../components/Portfolio/PortfolioEdwb';
import PortfolioSteps from '../../components/Portfolio/PortfolioSteps';
import PortfolioMstr from '../../components/Portfolio/PortfolioMstr';
import PortfolioEb from '../../components/Portfolio/PortfolioEb';
import PortfolioEdl from '../../components/Portfolio/PortfolioEdl';
import PortfolioOpioid from '../../components/Portfolio/PortfolioOpioid';
import PortfolioTwitter from '../../components/Portfolio/PortfolioTwitter';
import PortfolioHealthInsights from '../../components/Portfolio/PortfolioHealthInsights';
import PortfolioNcbp from '../../components/Portfolio/PortfolioNcbp';
import PortfolioHockey from '../../components/Portfolio/PortfolioHockey';
import PortfolioOverview from '../../components/Portfolio/PortfolioOverview';
import PortfolioWorldAware from '../../components/Portfolio/PortfolioWorldAware';

import folioHero from '../../assets/images/heros/folio-hero.jpg';

export default function Portfolio() {
  return (
    <div className="Portfolio">

      <MetaData
        title="Portfolio"
        description="See how BNL Consulting has leveraged industry-leading technologies to create simple, flexible,
          and elegant visual tools to access and manage our clients’ data."
        image={folioHero}
      />

      <Hero
        image={folioHero}
        title="PORTFOLIO"
        detail="Some examples of past and current projects"
      />

      <PortfolioOverview/>
      <PortfolioOpioid/>
      <PortfolioWorldAware/>
      <PortfolioEdl/>
      <PortfolioEdwb/>
      <PortfolioNcbp/>
      <PortfolioCdc/>
      <PortfolioTwitter/>
      <PortfolioHealthInsights/>
      <PortfolioHockey/>
      <PortfolioSteps/>
      <PortfolioMstr/>
      <PortfolioEb/>

      <ContactUs>
        Want to see what we can build for your organization?
      </ContactUs>

    </div>
  );
}
