import React from 'react';

import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF, faLinkedinIn, faTwitter, faYoutube} from '@fortawesome/free-brands-svg-icons';

import './SocialButton.css';

const FACEBOOK = 'facebook';
const TWITTER = 'twitter';
const LINKEDIN = 'linkedin';
const YOUTUBE = 'youtube';

function SocialButton(props) {
  const {type, link, className, ariaLabel} = props;
  const combinedClass = `SocialButton ${className} ${type}`;

  function getIcon(type) {
    switch (type) {
      case FACEBOOK:
        return <FontAwesomeIcon icon={faFacebookF}/>;
      case TWITTER:
        return <FontAwesomeIcon icon={faTwitter}/>;
      case LINKEDIN:
        return <FontAwesomeIcon icon={faLinkedinIn}/>;
      case YOUTUBE:
        return <FontAwesomeIcon icon={faYoutube}/>;
      default:
        return null;
    }
  }

  return (
    <a className={combinedClass} href={link} target="_blank" rel="noreferrer" aria-label={ariaLabel}>
      {getIcon(type)}
    </a>
  );
}

SocialButton.propTypes = {
  type: PropTypes.oneOf([FACEBOOK, TWITTER, LINKEDIN, YOUTUBE]),
  link: PropTypes.string,
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
};

export default SocialButton;
