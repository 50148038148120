import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types'

function BlogGhostSubscribe({
    buttonColor, 
    backgroundColor, 
    textColor, 
    title, 
    description, 
    icon, 
    site,
}) {
    const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
        const container = containerRef.current;

        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js';
        script.async = true;

        // set data attributes for widget
        script.setAttribute('data-button-color', buttonColor || '#15171A');
        script.setAttribute('data-background-color', backgroundColor || '#08090C')
        script.setAttribute('data-text-color', textColor || '#FFFFFF');
        script.setAttribute('data-title', title || ' ');
        script.setAttribute('data-description', description || 'Advice, news, and assorted musings from our team.');
        script.setAttribute('data-icon', icon || 'https://bnl-consulting.ghost.io/content/images/size/w192h192/size/w256h256/2017/11/logo.png');
        script.setAttribute('data-site', site || 'https://bnl-consulting.ghost.io/');

        container.appendChild(script);

        return () => {
            container.removeChild(script);
        };
    }
  }, [buttonColor, backgroundColor, textColor, title, description, icon, site]);

  // overrides the default 100vh that pushes all other content below fold
  const containerStyles = {
    height: '300px',
  }

  return (
     <div ref={containerRef} className="mb-4" style={containerStyles}></div>
  );
}

BlogGhostSubscribe.propTypes = {
    buttonColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.string,
    site: PropTypes.string,
};

export default BlogGhostSubscribe;
