import React from 'react';
import PropTypes from 'prop-types';

import purify from 'dompurify';

/**
 * Legacy blogs supported embedded text tags that would be rendered as html.
 * This function makes that conversion.
 *
 * Supported:
 * [[codeblock]]
 * [[embed]]
 * [[*]]
 *
 * @param input
 * @returns {string}
 */
function formatBody(input = '') {

  // Replace [[codeblock]] which removes <p> tags as well
  const preRegex = /<pre>([\s\S]*?)<\/pre>/igm;

  input = input.replace(preRegex, function() {
    return '<pre class="prettyprint">' +
      window.PR.prettyPrintOne(arguments[1].replace(/<p[^>]*>/igm, '').replace(/<\/p>/igm, '')) +
      '</pre>';
  });

  // Replace [[embed]] which removes <p> tags as well
  const embedRegex = /\[\[embed]]([\s\S]*?)\[\[\/embed]]/igm;
  input = input.replace(embedRegex, function() {
    return '<div class="embed-responsive embed-responsive-16by9">' +
      '<iframe class="embed-responsive-item" src="//' + arguments[1] + '" allowfullscreen></iframe>' +
      '</div>';
  });

  // Replace all other [[tags]]
  const tagRegex = /\[\[([A-Z][A-Z0-9]*)]]([\s\S]*?)\[\[\/(\1)]]/igm;
  input = input.replace(tagRegex, '<$1>$2</$1>');

  return input;
}

function BlogHtmlBody(props) {
  const {className, post} = props;

  // Sanitize html before rendering
  const getSanitizedHtml = () => {
    return {
      __html: purify.sanitize(formatBody(post.html), {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['src', 'frameborder', 'allow', 'allowfullscreen']
      })
    };
  };

  return (
    <div className={className} dangerouslySetInnerHTML={getSanitizedHtml()}/>
  );
}

BlogHtmlBody.propTypes = {
  className: PropTypes.string,
  post: PropTypes.shape({
    html: PropTypes.string
  }).isRequired
};

export default BlogHtmlBody;


