import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import CaseStudyDivider from '../../components/CaseStudies/CaseStudyDivider';
import CaseStudySection from '../../components/CaseStudies/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudies/CaseStudyImage';
import QuoteBubble from '../../components/Common/QuoteBubble/QuoteBubble';
import NumberList from '../../components/CaseStudies/NumberList';

import EncounterDataHeroImage from '../../assets/images/edwb/edwb-banner.jpg';
import EncounterDataImage from '../../assets/images/edwb/Encounters.png';
import EncounterDataProfiling from '../../assets/images/edwb/Profiling.png';
import EncounterDataDetails from '../../assets/images/edwb/Details.png';
import EncounterDataScorecard from '../../assets/images/edwb/Scorecard-Integrity.png';

import caseStudyPdf from '../../assets/documents/edwb/case-study.pdf';

export default function EncounterData() {
  return (
    <div className="EncounterData">

      <MetaData
        title="Encounter Data Case Study"
        description="Learn how we developed a user-friendly web application to help a federal health organization
          visualize, explore, and analyze Medicare Part C claims data."
        image={EncounterDataHeroImage}
      />

      <div>
        <img
          className="responsive-image w-100 border-bottom-banner"
          alt="Encounter Data Case Study Application"
          src={EncounterDataHeroImage}
        />
      </div>

      <div className="container">

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">Encounter Data</h1>
          <h5 className="text-muted">Centers for Medicare & Medicaid Services | 2014-2016</h5>
          <a 
            className="btn btn-white"
            href={caseStudyPdf}
            download="Encounter_Data_Case_Study"
            target="_blank"
            rel="noreferrer">
              Download Case Study
          </a>
        </div>

        <CaseStudyDivider>Background</CaseStudyDivider>

        <CaseStudySection>
          The client for this effort is a Federal Health Organization
          within the United States Department of Health and Human Services
          that administers the Medicare program and works in partnership
          with state governments to administer Medicaid, the State Children's
          Health Insurance Program, and health insurance portability standards.
        </CaseStudySection>

        <CaseStudyDivider>The Problem</CaseStudyDivider>

        <CaseStudySection>
          <p>
            Generally speaking, the organization wanted to be able to
            securely visualize the wealth of data at their disposal in
            an intuitive, insightful interface. More specifically, new
            regulations had been instituted which required contracts to
            submit their encounter data in a more timely manner, and so
            the team needed a user-friendly platform to be able to track
            this submission requirement and the corresponding history
            /status reports.
          </p>
          <p>
            They desired snapshot capabilities with various filtering
            mechanisms and user-generated Query Definition Files (QDFs)
            to enable a more detailed search using the raw data. The
            expectation was that QDF creation would give users the
            ability to generate customized reports by selecting à la
            carte which fields to display. These reports would enable
            the team to compare organizations and their contracts both
            to each other and to standard benchmarks. This in turn
            would allow contracts to be displayed in a report card
            manner based on reporting history and service performance.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Process</CaseStudyDivider>

        <CaseStudySection>
          After analyzing the requirements passed along by the
          organization, we decided to focus on implementing the
          following four features:
        </CaseStudySection>

        <CaseStudySection>
          <NumberList items={[
            "Linking the SAS data sources to a highly visual UI via RESTful services",
            "Authentication using JavaScript/AngularJS using Javascript Web Tokens",
            "User-initiated Query Definition File (QDF) generation",
            "User-initiated PDF generation for the scorecard"
          ]}/>
        </CaseStudySection>

        <CaseStudySection>
          <p>
            We began by instituting the data delivery structure for
            SAS to load the data to MySQL tables, which in turn are
            connected to RESTful services tailored for use in the
            user interface (UI). To ensure the data is being
            accessed securely, we utilized Spring services to
            provide a token during a user's successful login.
            The UI uses that token (which is verified by services
            as valid) to grant ongoing access to the application
            and its data.
          </p>
          <p>
            For QDF generation and saving we integrated FileSaver.js.
            The PDF generation is handled by PhantomJS on the server
            side, and the server regenerates the PDF information any
            time the data changes for a given contract and year
            combination. By using this method of generation, bulk
            requests for PDF documents can be returned very quickly.
          </p>
          <p>
            We used multiple JavaScript graphing libraries to
            implement the desired data visualizations, including:
            D3, Rickshaw, and NVD3. To assist with object mapping
            and manipulation, we incorporated the Underscore.js
            library. And because the application is built with
            AngularJS, we leveraged built-in functionality in
            conjunction with the aforementioned libraries to craft
            custom directives that produce powerful, dynamic
            graphical elements.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>The Result</CaseStudyDivider>

        <CaseStudyImage src={EncounterDataImage} size="wide">
          Encounter Data Workbench Interface
        </CaseStudyImage>

        <CaseStudySection>
          <p>
            The resulting web-based application was comprised of
            features for Encounters, Profiling, Details, Scorecards,
            Integrity, and Administration.
          </p>
          <p>
            The Encounter view (pictured above) provides an
            introduction to Medicare Advantage Part C encounters
            through carefully selected summary data. This summary
            data is paired with a series of visual tools, filters
            to customize the analytics, and shareable queries that
            make collaboration quick and easy.
          </p>
        </CaseStudySection>

        <CaseStudyImage src={EncounterDataProfiling}>
          Examples of the advanced visualizations used in a Profiling view
        </CaseStudyImage>

        <CaseStudySection>
          The Profiling page gives users the ability to search for and compare organizations based on
          benchmarks and reported measures. A highly-visual statistical process is employed to allow
          users to effectively identify organizations with incomplete or potentially erroneous data
          that warrants further investigation. The tools available on this page also enable users to
          compare an organization to others with similar distance scores across various types of
          encounters.
        </CaseStudySection>

        <CaseStudyImage src={EncounterDataDetails} size="wide">
          Demographic summaries are paired with core contract and organization stats
        </CaseStudyImage>

        <CaseStudySection>
          <p>
            The Details page dives in a little bit deeper than the Profiling page, allowing users to compare
            organizations or contracts on more fine-tuned characteristics, such as demographic counts, BETOS
            codes, or HCPCS code distributions. Those characteristics are paired with a high level demographic
            overview of contracts, parent organizations, and benchmarks, giving users an understanding of the
            similarities and differences between the populations.
          </p>

          <p>
            The purpose of the Scorecard is to evaluate encounter reporting at the contract level. This page
            synthesizes all of the information about a given contract into a tidy, handsomely-designed report card
            that displays essential information including: enrollment, reporting, and service type data and ratios.
            The card depicts the performance of a contract compared to the contract’s parent organization, 25 Parent
            Organizations with Highest Enrollment (T25), Medicare Advantage (MA), and Fee For Service (FFS). These
            comparisons are also broken out into different encounter types such as Institutional Inpatient,
            Institutional Outpatient, Institutional Other, Professional, and DME.
          </p>
        </CaseStudySection>

        <CaseStudyImage src={EncounterDataScorecard} size="wide">
          (L) A sample Scorecard with enrollment, reporting, and performance data <br/>
          (R) a sample Integrity view with submissions breakdowns and outliers
        </CaseStudyImage>

        <CaseStudySection>
          The value of the conclusions drawn from encounter data depends on the accuracy of the
          data submitted. When evaluating these submissions’ accuracy, qualitative approaches such
          as chart reviews or interviews can prove valuable, but they are also time-consuming,
          laborious, and expensive. The application’s Integrity functionality instead takes a
          quantitative approach, identifying submitted values that fall outside of an expected range
          of frequency and thus are likely inaccurate. This affords decision-makers the luxury of
          avoiding misguided and potentially costly actions based on conclusions drawn from erroneous
          data.
        </CaseStudySection>

        <CaseStudySection>
          <QuoteBubble client="Director of Federal Health Organization">
            I have had the pleasure to observe BNL’s work as part of [our] efforts to
            further improve care for beneficiaries enrolled in the Medicare Advantage program.
            Their ability to meet dynamic and challenging project goals within tight deadlines,
            coupled with their technical expertise and innovative thinking is refreshing in today’s
            contracting environment.
          </QuoteBubble>
        </CaseStudySection>

      </div>

      <ContactUs>
        Want to see what we can build for you?
      </ContactUs>

    </div>
  );
}
