import React from 'react';
import PropTypes from 'prop-types';

function FormattedDate(props) {
  const {className, date} = props;

  const dateObject = new Date(date);
  const dateString = dateObject.toLocaleDateString &&
    dateObject.toLocaleDateString(undefined, {year: 'numeric', month: 'short', day: 'numeric'});

  return (
    <span className={className}>
      {dateString}
    </span>
  );
}

FormattedDate.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string
};

export default FormattedDate;
