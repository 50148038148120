import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import SystemHeroImage from '../../assets/images/heros/system-modernization.jpg';
import VectorSystem from '../../assets/images/system-modernization/vector-systems.jpg';
import SystemUptime from '../../assets/images/system-modernization/system-uptime.jpg';
import CyclopsDesktop from '../../assets/images/cyclops/cyclops-macbook-desktop.png';

export default function SystemModernization() {
  return (

    <div className="SystemModernization">

      <MetaData
        title="Modernize Your Legacy IT Systems and Applications"
        description="Have you found that the old way of doing things isn’t always the best way? Let us help you bring
        your legacy IT infrastructure into the future by modernizing."
        image={SystemHeroImage}
      />

      <Hero
        image={SystemHeroImage}
        title="System Modernization"
        detail="Improve systems value and output while cutting costs"
        reducedFont={true}
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          Your legacy systems have served you well in the past. Heck, they may still be chugging along at a
          respectable pace, but have you slowly started to realize that finding the staff to support and use
          these legacy systems grows harder and harder?
        </p>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2 className="h4">
              Legacy System Modernization
            </h2>
            <p>
              How much does your organization spend to maintain outdated and low-functioning software?
              Modernization puts an end to the practice of wasting money spent on archaic enterprise data
              systems and has the potential to deliver far more efficiency to your enterprise at a much lower
              cost. Additionally, cloud adoption has the added benefit of putting your critical systems on
              scalable platforms that are evolving in place and adaptive to increases in user volume, data
              volume, and resource demands.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL System Modernization"
                 src={VectorSystem}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h3 className="h4">
              Reliable System Architecture
            </h3>
            <p>
              When we modernize your systems, we anticipate your desired business outcomes and help you
              adapt to those changes. We also encourage adherence to common, well-documented standards,
              Agile build processes, and readily available cloud-first technology. This process leads to
              a system that will deliver value, improve performance, and save money from day one.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="System Uptime Monitor"
                 src={SystemUptime}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h3 className="h4">
              Easily Monitored Infrastructure
            </h3>
            <p>
              Outdated legacy systems slow your enterprise’s productivity, cost production time and
              resources due to outages, grow too expensive to maintain, and fail to scale to the size
              you need. Our modernization efforts produce reliable, easy-to-monitor systems that enterprises
              can effortlessly staff long-term. We construct every system to be compatible with industry
              standards while at the same time respecting timelines and budget constraints.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="Cyclops System Scheduling and Monitoring"
                 src={CyclopsDesktop}/>
          </div>
        </div>

        <div className="p-5"/>

      </div>

      <ContactUs>
        Want to improve your systems value while reducing cost?
      </ContactUs>

    </div>
  );
}
