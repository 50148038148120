import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import twitterIcon from '../../assets/images/twitter/twitter-icon.png';
import twitterIpads from '../../assets/images/twitter/twitter-ipads-xl.png';
import twitterIpadsSm from '../../assets/images/twitter/twitter-ipads-sm.png';
import twitterIpadsMd from '../../assets/images/twitter/twitter-ipads-md.png';
import twitterIpadsLg from '../../assets/images/twitter/twitter-ipads-lg.png';
import boxIpads from '../../assets/images/twitter/box-ipads-xl.png';
import boxIpadsSm from '../../assets/images/twitter/box-ipads-sm.png';
import boxIpadsMd from '../../assets/images/twitter/box-ipads-md.png';
import boxIpadsLg from '../../assets/images/twitter/box-ipads-lg.png';

export default function PortfolioTwitter() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#40454b" borderColor="#7c7c7c">

      <div className="pb-5 col-4 offset-4">
        <img
          className="m-auto icon-image"
          src={twitterIcon}
          alt="BNL Blue Icon"
        />
      </div>

      <ResponsiveImage
        className="pb-5"
        alt="BNL Big Data Twitter Pipeline Search iPad app"
        image={twitterIpads}
        largeImg={twitterIpadsLg}
        mediumImg={twitterIpadsMd}
        smallImg={twitterIpadsSm}
      />

      <PortfolioDescription
        title="Big Data Search"
        description="A mobile and web application that collects a large
        volume of live healthcare-related tweets and exposes that data through a lucene
        text search engine for rapid search and analysis."
        demo="Request Demo"
        demoLink="https://bnlconsulting.com/contact"
        demoLinkText="Contact Us"
        createdFor="BNL Consulting LLC"
        createdForLink="https://bnlconsulting.com/"
      />

      <ResponsiveImage
        alt="BNL Machine Learning Box Office Sentiment iPad app"
        className={'pb-5 pt-5'}
        image={boxIpads}
        largeImg={boxIpadsLg}
        mediumImg={boxIpadsMd}
        smallImg={boxIpadsSm}
      />

      <PortfolioDescription
        title="Box Office Sentiment"
        description="A mobile and web application that collects Rotten Tomatoes reviews and Twitter information
        about current box office movies. The app analyzes each movie’s tweets with a sentiment classifier and
        then compares trends in box office sales, review scores, and sentiment to demonstrate a correlation
        between derived sentiment and review scores."
        demo="Request Demo"
        demoLink="https://bnlconsulting.com/contact"
        demoLinkText="Contact Us"
        createdFor="BNL Consulting LLC"
        createdForLink="https://bnlconsulting.com/"
      />

    </PortfolioSection>
  );
}
