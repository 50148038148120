import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import CaseStudyDivider from '../../components/CaseStudies/CaseStudyDivider';
import CaseStudySection from '../../components/CaseStudies/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudies/CaseStudyImage';

import NCBPBanner from '../../assets/images/ncbp/ncbp-banner.jpg';
import NCBPScreenUI from '../../assets/images/ncbp/ncbp-screen-ui-lg.jpg';

import caseStudyPdf from '../../assets/documents/ncbp/case-study.pdf';

export default function NcbpPreparedness() {
  return (
    <div className="CdcWisqars">

      <MetaData
        title="National Collaborative for Bio-Preparedness Case Study"
        description="Learn more about the custom mobile applications and analytics framework we developed for the
          Centers for Disease Control and Prevention (CDC)."
        image={NCBPBanner}
      />

      <div>
        <img
          className="responsive-image w-100 border-bottom-banner"
          alt="CDC Wisqars iPads"
          src={NCBPBanner}
        />
      </div>

      <div className="container">

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">National Collaborative for Bio-Preparedness Case Study</h1>

          <a 
            className="btn btn-white"
            href={caseStudyPdf}
            download="Ncbp_Case_Study"
            target="_blank"
            rel="noreferrer">
              Download Case Study
          </a>
        </div>

        <CaseStudyDivider>Overview</CaseStudyDivider>

        <CaseStudySection>
        The National Collaborative for Bio-Preparedness (NCB-Prepared) responds to federal and state
        agencies’ need to accurately detect and rapidly analyze biological hazards to ensure public health
        and safety. They accomplish this feat through a comprehensive system that improves surveillance,
        awareness, and responsiveness to emergent threats. These efforts ultimately support better care
        and health outcomes for Americans. NCB-P Visualization demonstrates capabilities to access data
        sources at local, state, and federal levels. Additionally, they perform analytics on the data and display analytical and other results that support bio-surveillance efforts to empower both 
        decision-makers and first-responders.
        </CaseStudySection>

        <CaseStudyDivider>Challenge</CaseStudyDivider>

        <CaseStudySection>
          <p>
          The NCBP had previously created an analytics 
          platform for emergency medical services
          records that allowed users to quickly visualize 
          and analyze various data types from both
          private partners and local, state, and federal agencies. 
          As their system grew with more
          users, data, and regulatory requirements the
          NCBP turned to BNL to help with these three
          challenges.
          </p>
          
          <ol type="1">
            <li>
              <p>
              To improve the performance and usability of the user interface. The volume of data previously
              presented to the user interfered with the overall user experience as the application was not 
              intuitive enough as to how items worked and flowed. Users had an idea of what they were trying
              to search for, but they could not view the important information because of the data’s filtering
              process. BNL needed to work with both front and back-end teams to utilize the data in the way
              users wanted without hampering what the application could do with that information from a user
              perspective.
              </p>
            </li>
            <li>
              <p>
              To move the application to a HIPAA compliant cloud environment. When the Department of
              Homeland Security gave NCBP a new requirement stating that their system would need to be
              fully HIPAA compliant, this provision proved not feasible with their previous on-premises hosting.
              </p>
            </li>
            <li>
              <p>
              To analyze the back-end architecture and improve the performance of the ETL and real-time 
              analytic processes. Their system relied on many single points of failure, and as the system came
              to be accessed by more users, scalability grew to a level of concern. Simultaneously, The ETL
              (extract, transform, load) aspect of data ingestion that initially existed was slow, error-prone, and
              often crashed.
              </p>
            </li>
          </ol> 
          
        </CaseStudySection>

        <CaseStudyDivider>The Process</CaseStudyDivider>

        <CaseStudySection>
        <ul>
            <li>
              <p>
              To improve the NCBP User Interface’s performance, the BNL UI team reviewed and updated
              the current application targeting specific shortcomings. The team added a data filtering 
              ability so a user would not have to navigate away from the data results to change their filters. This
              modification allowed users to see immediately how the filter change affected visualizations.
              BNL also updated the visual workflows to make the data displays more intuitive as part of the
              application filtering. We expanded the normal filter boxes into part of the application display
              so that utilizing a graph visualization allowed users to drill into the data from that portion of
              the graph.
              </p>
            </li>
            <li>
              <p>
              BNL answered the request for a HIPAA-compliant platform by migrating the application and
              data from a non-compliant environment to a HIPPA-compliant Amazon Web Service cloudbased environment. 
              BNL’s system administrator utilized DevOps techniques to create infra-
              structure as a code solution that would make the NCBP environment simple to transfer.
              </p>
            </li>
            <li>
              <p>
              BNL utilized the AWS Total Cost of Ownership calculator as well as the AWS Simple Monthly
              Calculator to provide detailed cost estimates to the customer before the migration. Additionally, 
              as the project’s scope changed over time, BNL easily provided updated estimates for the
              customer to make informed decisions on the cost/benefit ratio of new features or infrastructure.
              </p>
            </li>
            <li>
              <p>
              The BNL team reviewed the current architecture and performance of the ETL and real-time
              analytics processes. We also implemented significant architectural changes with a focus on
              simplifying the complex and error-prone ingestion process. The BNL team enabled a CI/CD
              pipeline to replace the manual process. The CI/CD pipeline decreased the build/deployment
              time, increased the reliability of deployments, and allowed the team to add automated tests
              to the environment.
              </p>
            </li>
          </ul> 
        </CaseStudySection>

        <CaseStudyDivider>Results</CaseStudyDivider>

        <CaseStudyImage src={NCBPScreenUI} size="wide" />

        <CaseStudySection>
        BNL successfully met all the expectations for this engagement by delivering a high-performance
        user-friendly interface, increasing the performance and reliability of the ETL and analytic process,
        and moving the entire application to a HIPAA-compliant AWS cloud environment. Soon after BNL
        completed this engagement, the NCBP application was transitioned to BioSpatial https://www.biospatial.io/ for commercialization.
        </CaseStudySection>
      </div>

      <ContactUs>
        Want to see what we can build for you?
      </ContactUs>

    </div>
  );
}
