import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';

function NumberList(props) {
  const {className, items} = props;

  const renderedItems = items?.map((child, index) => (
    <div key={index} className="col-md-6 pb-4">
      <div className="row">
        <div className="col-2">
        <span className="fa-layers fa-1x m-3">
          <FontAwesomeIcon icon={faCircle} transform="grow-18" color="#3371a8"/>
          <span className="fa-layers-text fa-inverse font-weight-bold">
            {index + 1}
          </span>
        </span>
        </div>
        <div className="col-10 pr-2">
          {child}
        </div>
      </div>
    </div>
  ));

  return (
    <div className={className}>
      <div className="row">
        {renderedItems}
      </div>
    </div>
  );
}

NumberList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.node)
};

export default NumberList;
