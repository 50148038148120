import React from 'react';
import {Link} from 'react-router-dom';

import DelaunayTexture from '../../assets/images/intro/Delaunay.jpg'

export default function HomeContact() {

  const backgroundStyle = {
    background: `url(${DelaunayTexture}) no-repeat center center`,
    backgroundSize: 'cover'
  }

  return (
    <div style={backgroundStyle}>
      <div className="container py-5 text-center">
        <div className="h4 text-white font-weight-light my-4">
          Want to learn more about our analytics, visualization, or integration solutions?
        </div>
        <Link className="btn btn-light my-4" to="/contact">
          Request A Demo
        </Link>
      </div>
    </div>
  );
}
