import React from 'react';

import Hero from '../../components/Common/Hero/Hero';
import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';

import DataAnalyticsHeroImage from '../../assets/images/heros/data-analysis-hero.png';
import VectorAnalytics from '../../assets/images/data-analytics/vector-analytics.jpg';
import FluTrends from '../../assets/images/data-analytics/Flu-Trends.png';
import BoxOffice from '../../assets/images/data-analytics/Box-Office-Map.jpg';
import NCBPMap from '../../assets/images/data-analytics/NCBP-Map.jpg';

export default function DataAnalytics() {
  return (
    <div className="DataAnalytics">

      <MetaData
        title="Data Analytics Solutions for Actionable Intelligence"
        description="We stay ahead of cutting-edge data analytics methodologies to provide better outcomes
        for your big data. Get your free data solutions consultation today."
        image={DataAnalyticsHeroImage}
      />

      <Hero
        image={DataAnalyticsHeroImage}
        title="DATA ANALYTICS"
        detail="Transform your raw data with advanced analytics"
        reducedFont={true}
      />

      <div className="container">

        <p className="lead pt-5 pb-3">
          Raw data on its own isn’t worth much. But when you translate it into insights, it’s as good as gold.
          We have all the tools to unlock your data's value and harness the true power of your organization’s information.
        </p>

        <p>
          As an industry leader in the development and delivery of data-analytics solutions,
          we can utilize the full power of tools like SAS, Python, R, and other Open Source tools,
          we can help you innovate for the ever-changing future.
        </p>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h2 className="h3">
              Data Analytics Consulting
            </h2>
            <p>
              We help our clients identify trends, areas they could improve, and ways to achieve their goals,
              all by enabling them to turn their data into actionable intelligence. Your data has enormous value,
              and our team has the skills and experience to extract and deliver the most relevant information to
              both your organization and its end users.
            </p>
            <p>
              As an industry leader in the development and delivery of data-analytics solutions, we can utilize
              the full power of tools like <a href="https://www.sas.com/">SAS</a>
              , <a href="https://www.python.org/">Python</a>
              , <a href="https://www.r-project.org/">R Studio</a>
              , and <a href="https://awesomerank.github.io/lists/bulutyazilim/awesome-datascience.html">other tools</a>
              , we can help you innovate for the ever-changing future.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL Data Analytics Consulting"
                 src={VectorAnalytics}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h3>
              Better Inputs, Better Output
            </h3>
            <p>
              The insights you gain about your organization are only as good as your data sources.
              That's why we make sure your data is in the most usable format. By taking advantage of SAS’s full
              analytics suite and other data processing tools, we turn your data into robust information derived
              from rich data. These practices lead to valuable insights that help your business run smarter and
              more efficiently.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="BNL Google Flu Trends Data Visualization"
                 src={FluTrends}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <h3>
              Descriptive And Predictive
            </h3>
            <p>
              What if we told you that we could predict influenza outbreaks down to the ZIP Code? Or that we
              can accurately spotlight high-risk targets in real-time for customs agents? At BNL Consulting,
              we understand that we can use data to describe what has already happened and anticipate what’s
              likely to happen. We develop advanced tools for our clients that enable them to seize control
              of their futures by turning insightful facts into powerful, reliable forecasts.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12 offset-lg-1 offset-sm-0">
            <img className="responsive-image"
                 alt="BNL North Carolina Bio-preparedness Map Visualization"
                 src={NCBPMap}/>
          </div>
        </div>

        <hr className="my-5"/>

        <div className="row">
          <div className="col-lg-6 col-sm-12 offset-lg-1 offset-sm-0 order-lg-last">
            <h3>
              Iterative Value
            </h3>
            <p>
              We live on the cutting edge of data analytics which makes outdated insights virtually worthless.
              While we rely on core statistical methods that have repeatedly proven useful, our approach to turning
              data into information — and information into intelligence — continually evolves. This way, our clients
              always remain ahead of the curve. In a moment-to-moment world, we iterate in real-time to provide you
              with the most relevant, valuable intelligence on the market.
            </p>
          </div>
          <div className="col-lg-5 col-sm-12">
            <img className="responsive-image"
                 alt="BNL Box Office Search Trends by Country Choropleth Map"
                 src={BoxOffice}/>
          </div>
        </div>

        <div className="p-5"/>

      </div>

      <ContactUs>
        Looking to turn your data into actionable intelligence?
      </ContactUs>

    </div>
  );
}
