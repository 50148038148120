import React from 'react';

import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faRedditAlien,
  faPinterestP
} from '@fortawesome/free-brands-svg-icons';

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  RedditShareButton,
  TwitterShareButton
} from 'react-share';

import './SocialButton.css';

const FACEBOOK = 'facebook';
const TWITTER = 'twitter';
const LINKEDIN = 'linkedin';
const PINTEREST = 'pinterest';
const REDDIT = 'reddit';

function SocialShareButton(props) {
  const {type, media, className} = props;
  const combinedClass = `SocialShareButton SocialButton ${className} ${type}`;

  const url = window.location.href;

  function getButton(type) {
    switch (type) {
      case FACEBOOK:
        return (
          <FacebookShareButton url={url}>
            <span className={combinedClass}>
              <FontAwesomeIcon icon={faFacebookF} fixedWidth/>&nbsp; Like
            </span>
          </FacebookShareButton>
        );
      case TWITTER:
        return (
          <TwitterShareButton url={url}>
            <span className={combinedClass}>
              <FontAwesomeIcon icon={faTwitter} fixedWidth/>&nbsp; Tweet
            </span>
          </TwitterShareButton>
        );
      case LINKEDIN:
        return (
          <LinkedinShareButton url={url}>
            <span className={combinedClass}>
              <FontAwesomeIcon icon={faLinkedinIn} fixedWidth/>&nbsp; Share
            </span>
          </LinkedinShareButton>
        );
      case PINTEREST:
        return (
          <PinterestShareButton url={url} media={media}>
            <span className={combinedClass}>
              <FontAwesomeIcon icon={faPinterestP} fixedWidth/>&nbsp; Pin
            </span>
          </PinterestShareButton>
        );
      case REDDIT:
        return (
          <RedditShareButton url={url}>
            <span className={combinedClass}>
              <FontAwesomeIcon icon={faRedditAlien} fixedWidth/>&nbsp; Post
            </span>
          </RedditShareButton>
        );
      default:
        return null;
    }
  }

  return getButton(type);
}

SocialShareButton.propTypes = {
  type: PropTypes.oneOf([FACEBOOK, TWITTER, LINKEDIN, PINTEREST, REDDIT]),
  media: PropTypes.string, // required for pinterest
  className: PropTypes.string
};

export default SocialShareButton;
