import React from 'react';

import ResponsiveImage from '../Common/ResponsiveImage/ResponsiveImage';
import PortfolioDescription from './PortfolioDescription';
import PortfolioSection from './PortfolioSection';

import mstrSm from '../../assets/images/microstrategy/mstr-sm.png';
import mstrMd from '../../assets/images/microstrategy/mstr-md.png';
import mstrLg from '../../assets/images/microstrategy/mstr-lg.png';
import mstr from '../../assets/images/microstrategy/mstr-xl.png';

export default function PortfolioMstr() {

  return (
    <PortfolioSection className="text-white" backgroundColor="#37383a" borderColor="#232426">

      <ResponsiveImage
        alt="Custom microstrategy visualizations charts and maps"
        className="pb-5"
        image={mstr}
        argeImg={mstrLg}
        mediumImg={mstrMd}
        mallImg={mstrSm}
      />

      <PortfolioDescription
        title="Custom Microstrategy Visualizations"
        description="A collection of custom Microstrategy D3 visualizations created as part of
        the second phase of the Encounter Data Work Bench for a federal health agency. With these
        components, BNL enables a level of customization not previously possible on the Microstrategy
        platform, which includes support for custom properties, styles, and interactions."
        demo="Request Demo"
        demoLink="https://bnlconsulting.com/contact"
        demoLinkText="Contact Us"
        createdFor="Index Analytics"
        createdForLink="https://www.index-analytics.com/"
      />

    </PortfolioSection>
  );
}
