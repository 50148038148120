import React from 'react';

import MetaData from '../../components/Common/MetaData/MetaData';
import ContactUs from '../../components/Common/ContactUs/ContactUs';
import CaseStudyDivider from '../../components/CaseStudies/CaseStudyDivider';
import CaseStudySection from '../../components/CaseStudies/CaseStudySection';
import CaseStudyImage from '../../components/CaseStudies/CaseStudyImage';

import EdlBanner from '../../assets/images/edl/edl-banner.jpg';
import EdlScreenUI from '../../assets/images/edl/edl-screen.png';

import caseStudyPdf from '../../assets/documents/edl/case-study.pdf';

export default function EnterpriseDataLake() {
  return (
    <div className="CdcWisqars">

      <MetaData
        title="US Census Bureau
        Enterprise Data Lake"
        description="Learn more about the custom mobile applications and analytics framework we developed for the
          Centers for Disease Control and Prevention (CDC)."
        image={EdlBanner}
      />

      <div>
        <img
          className="responsive-image w-100 border-bottom-banner"
          alt="US Census Bureau Enterprise Data Lake Desktop"
          src={EdlBanner}
        />
      </div>

      <div className="container">

        <div className="text-center pt-5 pb-5">
          <h1 className="h2">US Census Bureau Enterprise Data Lake</h1>

          <a 
            className="btn btn-white"
            href={caseStudyPdf}
            download="Edl_Case_Study"
            target="_blank"
            rel="noreferrer">
              Download Case Study
          </a>
        </div>

        <CaseStudyDivider>Overview</CaseStudyDivider>

        <CaseStudySection>
            <p>
            The primary mission of the Census Bureau is conducting the U.S. Census every ten years, which allocates the
            seats of the U.S. House of Representatives to the states based on their population. In addition to the decennial
            census, the Census Bureau continually conducts dozens of other censuses and surveys, including the American Community Survey, the U.S. Economic Census, and the Current Population Survey. Furthermore, economic
            and foreign trade indicators released by the federal government typically contain data produced by the Census
            Bureau. The various censuses and surveys conducted by the Census Bureau help allocate over $400 billion in
            federal funds every year and help states, local communities, and businesses make informed decisions.
            </p>

            <p>
            The Enterprise Data Lake (EDL) serves as a critical project for the US Census Bureau, aiming to provide the following functional improvements to the organization:    
            </p>
        </CaseStudySection>

        <CaseStudyDivider>Challenge</CaseStudyDivider>

        <CaseStudySection>
            <p>
            The Census Bureau initially decided to develop the EDL independent of SAS. Their architecture centered on
            Hadoop and the AWS cloud platform with a focus on migrating analytic workload to open source tools, specifically
            Python. Although the architecture was generally sound, there were gaps for SAS workloads to move to the EDL.
            These obstacles to adoption meant that the Census Bureau had two basic options:
            </p>

            <ul>
            <li>
              <p>
              Force hundreds of SAS developers to train to use Python while hundreds of thousands of lines of SAS code
                would be re-written in Python another appropriate language
              </p>
            </li>
            <li>
              <p>
              Revisit the architecture and create a pathway for SAS workloads to migrate to the platform
              </p>
            </li>
          </ul>

          <p>
            The higher complexity and data volume requirements of some of the SAS applications further complicated option
            one. Early prototypes and analysis revealed that it would be incredibly costly to re-write, and would force parallel
            testing and survey processing to prove the accuracy of the code. This would stretch the capacity of the underlying system and would not be feasible in the short or long term.
          </p>

        </CaseStudySection>

        <CaseStudyDivider>The Process</CaseStudyDivider>

        <CaseStudySection>
        <p>
        The only feasible way forward was to bring in SAS subject matter experts to analyze the value and risk of the
        analytics catalog. This would determine a path forward that delivered on the Census Bureau’s open-source
        vision while also accounting for the Bureau’s existing investment in SAS. This plan did not limit the possibility of
        adopting 100% of open source analytic tools but made the cost of that adoption much lower and reduced the
        risk of analytic processes ceasing to exist during the migration.
        </p>

        <p>
        Finally, BNL helped the Census identify native cloud technologies that significantly improved the system’s
        overall performance and inherently scaled to meet volume. Now that the system is in place, the next challenge
        for the Census is to onboard data, programs and users onto the EDL platform. The Census leverages BNL’s
        expertise in open source analytics, SAS and Cloud technologies to create a survey processing system that runs
        on the EDL platform.
        </p>
        </CaseStudySection>

        <CaseStudyImage src={EdlScreenUI} size="wide" />

        <CaseStudyDivider>Our Solution</CaseStudyDivider>

        <CaseStudySection>
          <p>
            BNL determined the optimal SAS infrastructure sizings to create detailed architectural diagrams and necessary
            operating documents based on SAS 9.4 Foundation and SAS Viya. They also enabled SAS software operating
            and usage metrics for cloud deployments to be read with the software on-premises deployments. They defined
            and provided EDL teams with cloud administration, security, as well as monitoring and best practices for SAS
            9.4 Foundation and SAS Viya software usage in an AWS FEDRAMP environment. BNL created Ansible scripts
            used to provision transient instances of SAS running on AWS EC2.
          </p>
          <p>
            BNL also took responsibility for prototyping the methodology that Census survey teams could use to process
            their surveys. BNL built the prototype using AWS serverless services (API Gateway, Step Functions, Lambda,
            Dynamo DB, SQS, SNS) that allowed Census survey teams to schedule, provision, and run their survey programs without accessing SAS directly.
          </p>
        </CaseStudySection>

        <CaseStudyDivider>Results</CaseStudyDivider>

        <CaseStudySection>
            <p>
            BNL helped the Bureau create an enterprise-wide analytics platform running in the AWS Cloud with SAS as a
            critical element. BNL is currently working to onboard Census surveys to the EDL.
            </p>

            <p>
            With each iteration of the Census EDL, BNL Consulting completed the projects on time, on budget, and to the
            specifications of the client. BNL now performs AWS architecture, migration, and implementation in addition to SAS
            consulting for The Census Bureau.
            </p>
        </CaseStudySection>
      </div>

      <ContactUs>
        Want to see what we can build for you?
      </ContactUs>

    </div>
  );
}
