import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuoteLeft, faQuoteRight} from '@fortawesome/free-solid-svg-icons';

import './QuoteBubble.css'

function QuoteBubble(props) {
  const {className, children, client} = props;

  return (
    <div className={className}>
      <p className="QuoteBubble">
        <FontAwesomeIcon icon={faQuoteLeft} className='small text-primary mr-2' color="#428bca"/>
        {children}
        <FontAwesomeIcon icon={faQuoteRight} className='small text-primary ml-2'/>
      </p>
      <div className="text-uppercase small text-right mt-4 pr-4">
        {client}
      </div>
      <div className="clearfix"/>
    </div>
  );
}

QuoteBubble.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  client: PropTypes.string
};

export default QuoteBubble;
